.heroCardsTransition {
  position: relative;
  z-index: var(z-index-2);
  display: block;
  height: 150vh;
  background-color: transparent;

  --vertical-factor: 1;
  --hero-card-width: 25rem;
  --hero-card-gutter: var(--spacer-16);
  --hero-card-aspect-ratio: calc(4 / 3);
  --hero-card-height: calc(var(--hero-card-width) / var(--hero-card-aspect-ratio));
  --normalized-parallax-progress: calc(var(--parallax-progress) * 0.15);

  /* first cards column left offset from container */
  --first-column-x: calc((var(--hero-card-width) + var(--hero-card-gutter)) * -1 - (100vw - var(--hero-card-width)) / -2);

  @media (--md) {
    --first-column-x: -16.2rem;
    --hero-card-width: 37.8rem;
    --hero-card-gutter: var(--spacer-24);
  }

  @media (--lg) {
    --first-column-x: -20.9rem;
    --hero-card-width: 37.8rem;
    --normalized-parallax-progress: var(--parallax-progress) * 0.4;
  }
  @media (--xl) {
    --first-column-x: -40.9rem;
    --hero-card-width: 50rem;
  }
}

/* Background media */
.backgroundWrapper {
  position: absolute;
  z-index: var(--z-index-0);
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 110vh;
  transform: translate3d(0, calc(var(--parallax-progress) * 25vh), 0);

  --z-index-gradient-overlay: var(--z-index-3);

  .backgroundImageContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    will-change: transform;

    &::after {
      position: absolute;
      background: linear-gradient(0deg, rgb(0 0 0 / 20%) 0%, rgb(0 0 0 / 20%) 100%), linear-gradient(19deg, rgb(0 0 0 / 50%) 21.85%, rgb(0 0 0 / 0%) 73.55%);
      content: '';
      inset: 0;
      pointer-events: none;
    }
  }
}

/* Text content */
.contentWrapper {
  position: relative;
  display: flex;
  max-width: 144rem;
  flex-direction: column;
  padding-top: 22.5rem;
  margin: 0 auto;

  @media (--md) {
    padding-top: 32rem;
  }

  .content {
    width: 100%;
    align-items: center;
    align-self: var(--lockup-justify);
    padding: 0 var(--spacer-24);
    opacity: 0;

    @media (--md) {
      width: calc(var(--grid-computed-column-width) * 12 + var(--grid-gutter-size) * 11);
      padding-left: var(--spacer-40);
    }
  }
}

.cardsWrapper {
  /* position relative instead of absolute so it's placed at the baseline of the title block, so that every value used is based on that */
  position: relative;
  z-index: var(--z-index-1);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  /* pointer-events: none; */
}

.card {
  position: absolute;
  overflow: hidden;
  transform: translate3d(0, calc((10rem * (1 - var(--card-entry-progress, 0))) - (var(--normalized-parallax-progress, 0) * 100rem * var(--cards-column-translate-ratio))), 0);
  will-change: transform;

  --card-content-opacity: calc(var(--card-entry-progress, 0) * var(--card-scroll-progress, 1) * var(--card-max-opacity, 1));
  --base-top: 10%;
  --base-left: 0%;

  @media (--md) {
    transform: translate3d(0, calc((30rem * (1 - var(--card-entry-progress, 0))) - (var(--normalized-parallax-progress, 0) * 100rem * var(--cards-column-translate-ratio))), 0);
  }
}

$imgHeightAndGutter: var(--hero-card-height) + var(--hero-card-gutter);

$firstColX: var(--first-column-x);

/* Column 1 */
$colX: calc($firstColX + (var(--hero-card-width) + var(--hero-card-gutter)) * 0);
$colY: calc(10.9rem * var(--vertical-factor));
$mobileColY: calc(11rem * var(--vertical-factor));

$colTranslateRatio: 0.2;
$colMaxOpacity: 0.55;

.card:nth-child(1) {
  --card-max-opacity: $colMaxOpacity;
  --image-wall-increment: 0;
  --cards-column-translate-ratio: $colTranslateRatio;
  top: calc(var(--base-top) + calc($mobileColY + ($imgHeightAndGutter) * 0));
  left: calc(var(--base-left) + $colX);

  @media (--md) {
    top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 0));
  }
}

.card:nth-child(2) {
  --card-max-opacity: $colMaxOpacity;
  --image-wall-increment: 1;
  --cards-column-translate-ratio: $colTranslateRatio;
  top: calc(var(--base-top) + calc($mobileColY + ($imgHeightAndGutter) * 1));
  left: calc(var(--base-left) + $colX);
  @media (--md) {
    top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 1));
  }
}

/* Column 2 */
$colX: calc($firstColX + (var(--hero-card-width) + var(--hero-card-gutter)) * 1);
$colY: calc(24.3rem * var(--vertical-factor));
$mobileColY: calc(5.1rem * var(--vertical-factor));

$colTranslateRatio: 0.85;
$colMaxOpacity: 0.85;

.card:nth-child(3) {
  --card-max-opacity: $colMaxOpacity;
  --image-wall-increment: 2;
  --cards-column-translate-ratio: $colTranslateRatio;
  top: calc(var(--base-top) + calc($mobileColY + ($imgHeightAndGutter) * 0));
  left: calc(var(--base-left) + $colX);

  @media (--md) {
    top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 0));
  }
}

.card:nth-child(4) {
  --card-max-opacity: $colMaxOpacity;
  --image-wall-increment: 2;
  --cards-column-translate-ratio: $colTranslateRatio;
  top: calc(var(--base-top) + calc($mobileColY + ($imgHeightAndGutter) * 1));
  left: calc(var(--base-left) + $colX);

  @media (--md) {
    top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 1));
  }
}

/* Column 3  */
$colTranslateRatio: 0.4;
$colY: calc(2.4rem * var(--vertical-factor));
$colX: calc($firstColX + (var(--hero-card-width) + var(--hero-card-gutter)) * 2);
$colMaxOpacity: 1;
$mobileColY: calc(7.9rem * var(--vertical-factor));

.card:nth-child(5) {
  --card-max-opacity: $colMaxOpacity;
  --image-wall-increment: 0;
  --cards-column-translate-ratio: $colTranslateRatio;
  top: calc(var(--base-top) + calc($mobileColY + ($imgHeightAndGutter) * 0));
  left: calc(var(--base-left) + $colX);
  @media (--md) {
    top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 0));
  }
}

.card:nth-child(6) {
  --card-max-opacity: $colMaxOpacity;
  --image-wall-increment: 1;
  --cards-column-translate-ratio: $colTranslateRatio;
  top: calc(var(--base-top) + calc($mobileColY + ($imgHeightAndGutter) * 1));
  left: calc(var(--base-left) + $colX);
  @media (--md) {
    top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 1));
  }
}

/* Column 4 */

.card:nth-child(7),
.card:nth-child(8),
.card:nth-child(9) {
  display: none;
}

/* Column 5 */

.card:nth-child(10),
.card:nth-child(11),
.card:nth-child(12) {
  display: none;
}

/* TABLET/DESKTOP LAYOUT */

/* Column 1 */
$colX: calc($firstColX + (var(--hero-card-width) + var(--hero-card-gutter)) * 0);
$colY: calc(10.9rem * var(--vertical-factor));

$colTranslateRatio: 0.2;
$colMaxOpacity: 0.55;

@media (--md) {
  .card:nth-child(1) {
    --card-max-opacity: $colMaxOpacity;
    --image-wall-increment: 0;
    --cards-column-translate-ratio: $colTranslateRatio;
    top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 0));
    left: calc(var(--base-left) + $colX);
  }

  .card:nth-child(2) {
    --card-max-opacity: $colMaxOpacity;
    --image-wall-increment: 1;
    --cards-column-translate-ratio: $colTranslateRatio;
    top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 1));
    left: calc(var(--base-left) + $colX);
  }

  /* Column 2 */
  $colX: calc($firstColX + (var(--hero-card-width) + var(--hero-card-gutter)) * 1);
  $colY: calc(24.3rem * var(--vertical-factor));
  $mobileColY: calc(5.1rem * var(--vertical-factor));

  $colTranslateRatio: 0.85;
  $colMaxOpacity: 0.85;

  .card:nth-child(3) {
    --card-max-opacity: $colMaxOpacity;
    --image-wall-increment: 2;
    --cards-column-translate-ratio: $colTranslateRatio;
    top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 0));
    left: calc(var(--base-left) + $colX);
  }

  .card:nth-child(4) {
    --card-max-opacity: $colMaxOpacity;
    --image-wall-increment: 2;
    --cards-column-translate-ratio: $colTranslateRatio;
    top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 1));
    left: calc(var(--base-left) + $colX);
  }

  /* Column 3  */
  $colTranslateRatio: 0.4;
  $colY: calc(2.4rem * var(--vertical-factor));
  $colX: calc($firstColX + (var(--hero-card-width) + var(--hero-card-gutter)) * 2);
  $colMaxOpacity: 1;
  $mobileColY: calc(7.9rem * var(--vertical-factor));

  .card:nth-child(5) {
    --card-max-opacity: $colMaxOpacity;
    --image-wall-increment: 0;
    --cards-column-translate-ratio: $colTranslateRatio;
    top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 0));
    left: calc(var(--base-left) + $colX);
  }

  .card:nth-child(6) {
    --card-max-opacity: $colMaxOpacity;
    --image-wall-increment: 1;
    --cards-column-translate-ratio: $colTranslateRatio;
    top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 1));
    left: calc(var(--base-left) + $colX);
  }

  /* Column 4 */
  $colX: calc($firstColX + (var(--hero-card-width) + var(--hero-card-gutter)) * 3);
  $colY: calc(-18.5rem * var(--vertical-factor));
  $colTranslateRatio: 0.6;
  $colMaxOpacity: 0.8;

  .card:nth-child(7) {
    --card-max-opacity: $colMaxOpacity;
    --image-wall-increment: 0;
    --cards-column-translate-ratio: $colTranslateRatio;
    top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 0));
    left: calc(var(--base-left) + $colX);
    display: block;
  }

  .card:nth-child(8) {
    --card-max-opacity: $colMaxOpacity;
    --image-wall-increment: 1;
    --cards-column-translate-ratio: $colTranslateRatio;
    top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 1));
    left: calc(var(--base-left) + $colX);
    display: block;
  }

  .card:nth-child(9) {
    --card-max-opacity: $colMaxOpacity;
    --image-wall-increment: 1;
    --cards-column-translate-ratio: $colTranslateRatio;
    top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 2));
    left: calc(var(--base-left) + $colX);
    display: block;
  }

  /* Column 5 */
  $colX: calc($firstColX + (var(--hero-card-width) + var(--hero-card-gutter)) * 4);
  $colY: calc(-32rem * var(--vertical-factor));
  $colTranslateRatio: 0.2;
  $colMaxOpacity: 0.6;

  .card:nth-child(10) {
    --card-max-opacity: $colMaxOpacity;
    --image-wall-increment: 0;
    --cards-column-translate-ratio: $colTranslateRatio;
    top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 0));
    left: calc(var(--base-left) + $colX);
    display: block;
  }

  .card:nth-child(11) {
    --card-max-opacity: $colMaxOpacity;
    --image-wall-increment: 1;
    --cards-column-translate-ratio: $colTranslateRatio;
    top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 1));
    left: calc(var(--base-left) + $colX);
    display: block;
  }

  .card:nth-child(12) {
    --card-max-opacity: $colMaxOpacity;
    --image-wall-increment: 1;
    --cards-column-translate-ratio: $colTranslateRatio;
    top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 2));
    left: calc(var(--base-left) + $colX);
    display: block;
  }
}
