/*  if the module's parent section has rounded corners, we need to add some padding to the module to accomodate for the border-radius. can be overwritten in module specific css files with the --rounded-padding variable. example: modules with full width/height images don't need extra padding, as the image extends to the full width/height of the module */
.roundedBottomPadding {
  padding-bottom: var(--rounded-bottom-padding, var(--default-rounded-bottom-padding));

  --default-rounded-bottom-padding: var(--spacer-64);
  @media (--md) {
    --default-rounded-bottom-padding: var(--spacer-96);
  }
  @media (--lg) {
    --default-rounded-bottom-padding: var(--spacer-124);
  }
}

.roundedTopPadding {
  padding-top: var(--rounded-top-padding, var(--default-rounded-top-padding));

  --default-rounded-top-padding: var(--spacer-64);
  @media (--md) {
    --default-rounded-top-padding: var(--spacer-96);
  }
  @media (--lg) {
    --default-rounded-top-padding: var(--spacer-124);
  }
}

/* default spacing for modules directly preceding (covered) an above section or directly following a below section(revealed) by the sections parallax. these values determine the final "end" state of the parallax (when the section has a translateY value of 0). accounts for rounded corners overlap(--section-overlap). */

/* default spacing values can be overwritten in module specific css files with --module-padding vars or by cms spacers. */
.moduleWrapper {
  --parallax-extra-padding: 1;

  /* position relative to apply z-index */
  position: relative;

  /* default z-index to 1 (some module will override it if necessary
  eg: PotholeV4 */
  z-index: var(--module-z-index, var(--z-index-1));

  /* --fab-margin-top is defined in PageSection css */
  &.precedesFab {
    margin-bottom: var(--fab-margin-top);
  }

  &.followsFab {
    margin-top: calc(-1 * (var(--fab-height, 0px) + var(--fab-margin-top)));
  }

  @media (--md) {
    /* add some extra space to accomodate for the parallax reveal that travels all the way to the top */
    --parallax-extra-padding: 1.3;
  }

  &.isRevealedModule {
    --default-padding-top: var(--spacer-84);
    padding-top: calc(var(--module-padding-top, var(--default-padding-top)) + var(--section-overlap-top, 0));

    @media (--md) {
      --default-padding-top: var(--spacer-96);
    }

    @media (--lg) {
      --default-padding-top: var(--spacer-124);
    }
  }

  &.isCoveredModule {
    --padding-bottom: var(--module-padding-bottom, calc(var(--default-padding-bottom) * var(--parallax-extra-padding)));

    /* the negative bottom margin will determine how much the module is covered by the next element in its final covered state. default value can be can be overwritten in module specific css files with the --covered-module-negative-bottom-margin variable. */
    --default-negative-bottom-margin: calc((var(--spacer-height-after, var(--padding-bottom)) + var(--section-overlap-bottom)) / 2);
    padding-bottom: calc(var(--padding-bottom) + var(--section-overlap-bottom, 0));
    margin-bottom: calc(var(--covered-module-negative-bottom-margin, var(--default-negative-bottom-margin)) * -1);

    --default-padding-bottom: var(--spacer-84);

    @media (--md) {
      --default-negative-bottom-margin: calc(var(--spacer-height-after, var(--padding-bottom)) + var(--section-overlap-bottom));
      --default-padding-bottom: var(--spacer-124);
    }

    @media (--lg) {
      --default-padding-bottom: var(--spacer-160);
    }
  }

  &.isCoveredLastModuleInSectionWithFab {
    --default-padding-bottom: 0;
  }
}
