.word,
.letter {
  display: inline-block;
  text-decoration: inherit;
}

.emptySpace {
  display: inline;
  visibility: hidden;
}

/* allows marks to inherit parent properties like perspective */
.marks {
  display: contents;
}
