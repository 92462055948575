.listsContainer {
  composes: grid from '~g';
  position: relative;
}

.content {
  composes: subgrid from '~g';

  --subgrid-start: start;
  --subgrid-width: var(--grid-number-of-columns);
}

.eyebrow,
.titles,
.bodies,
.buttons {
  grid-column: start / end;

  @media (--md) {
    grid-column: col 2 / span 12;
  }

  .alignCenter & {
    @media (--md) {
      text-align: center;
    }
  }
}

.eyebrow {
  composes: eyebrow from '~typo';
}

.bodies {
  margin-top: var(--spacer-20);
  color: var(--color-alpha-light-50);

  @media (--lg) {
    grid-column: col 2 / span 8;
  }

  .alignCenter & {
    @media (--md) {
      grid-column: col 4 / span 8;
    }
  }
}

.buttons {
  margin-top: var(--spacer-32);
}

.lists {
  composes: subgrid from '~g';
  row-gap: var(--spacer-48);

  --list-width: 4;

  @media (--md) {
    row-gap: var(--spacer-64);

    --list-width: 6;
  }

  &.with3Columns {
    --list-item-margin: var(--spacer-16);

    @media (min-width: 960px) {
      --list-width: 4;
    }
  }

  &.with2Columns {
    --list-item-margin: var(--spacer-16);

    @media (--md) {
      --list-item-margin: var(--spacer-16);
      --list-width: 6;
    }

    @media (--lg) {
      --list-item-margin: var(--spacer-16);
      --list-width: 5;

      .list {
        --list-start: 2;

        &:nth-of-type(even) {
          --list-start: 8;
        }
      }
    }
  }
}

.list {
  grid-column: var(--list-start, auto) / span var(--list-width);

  @media (--md) {
    margin-bottom: var(--spacer-24);
  }

  @media (--lg) {
    margin-bottom: var(--spacer-16);
  }
}

.listLabel {
  composes: title6 from '~typo';
  margin-bottom: var(--list-item-margin);
}

.listItem {
  display: flex;
  align-items: center;
  margin-bottom: var(--list-item-margin);
}

.listItem:last-of-type {
  margin-bottom: 0;
}

.listItemIconWrapper {
  display: flex;
  height: 2.4rem;
  flex-shrink: 0;
  align-items: center;
  margin-right: var(--list-item-margin);
}

.listItemIcon {
  width: 1.9rem;
}

.listItemIcon path {
  fill: var(--color-accent);
}

.listItemText {
  composes: body from '~typo';
  color: var(--color-grey-70);
}
