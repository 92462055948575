@define-mixin centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.animationWrapper {
  @mixin centered;
  z-index: var(--z-index-1);
  width: 100%;
  height: 100%;

  --icon-line-width: 0.25rem;
  --icon-color: var(--color-grey-100);
  --icon-padding: 0.9rem;
}

.background {
  @mixin centered;
  width: 100%;
  border-radius: 50%;
  aspect-ratio: 1;
  background-color: var(--color-cobalt-dark);
  transform: translate(-50%, -50%) scale(0);
}

.dot {
  @mixin centered;
  height: 100%;
  aspect-ratio: 1;
  opacity: 0;
  transform: translate(-50%, -50%) rotate(-63deg);
  translate: unset;

  &::after {
    position: absolute;
    top: var(--icon-padding);
    left: 50%;
    display: block;
    width: var(--icon-line-width);
    border-radius: 50%;
    aspect-ratio: 1;
    background-color: var(--icon-color);
    content: '';
    translate: -50% 0;
  }
}

.tick,
.cross {
  @mixin centered;
  height: 100%;
  aspect-ratio: 1;
}

.line {
  @mixin centered;
  display: block;
  width: var(--icon-line-width);
  height: var(--icon-line-width);
  border-radius: var(--icon-line-width);
  background-color: var(--icon-color);
  opacity: 0;
}

.cross1,
.cross2,
.tickShort,
.tickLong {
  composes: line;
}

.cross1 {
  transform: translate(-50%, -50%) rotate(45deg);
}

.cross2 {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.tickShort {
  transform: translate(-1.44rem, -0.64rem) rotate(45deg);
  transform-origin: right;

  &:active {
    width: 0.8rem;
    transform: translate(-0.91rem, 0.45rem) rotate(45deg);
  }
}

.tickLong {
  @mixin centered;
  transform: translate(-0.26rem, 0.43rem) rotate(-45deg);
  transform-origin: left;

  &:active {
    width: 1.3rem;
  }
}

.circle {
  @mixin centered;
  height: calc(100% - var(--icon-padding) * 2);
  border: solid var(--icon-line-width) var(--color-alpha-light-50);
  border-radius: 50%;
  aspect-ratio: 1;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.7);
}
