@import '../FormInput.module.css';

@value dropdownOffset: -74;

.errorMessage {
  composes: errorMessage from '../FormInput.module.css';
}

.label {
  composes: label from '../FormInput.module.css';
  margin-left: var(--spacer-20);
}

.required {
  composes: required from '../FormInput.module.css';
}

.mask {
  composes: mask from '../FormInput.module.css';
  pointer-events: none;
}

@define-mixin selectedOption {
  background: radial-gradient(circle, rgb(157 157 255 / 10%), rgb(157 157 255 / 0%));
  opacity: 1;
  transform: translate3d(calc(var(--gradient-origin) - 50%), 0, 0);
}

.textInput {
  composes: textInput from '../TextInput/TextInput.module.css';
}

.trigger {
  composes: textInput from '../TextInput/TextInput.module.css';
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &[aria-expanded='true'] .caret {
    transform: rotate(180deg);
  }

  &:focus-visible {
    outline: 0;
  }
}

.caret {
  width: 8.5px;
  height: 8.5px;
  margin-right: var(--spacer-12);
  transform: none;
  transition: transform var(--duration-60ms) var(--ease-micro-bounce);

  & svg {
    display: block;
    fill: var(--color-grey-100);
  }
}

.selectContainer {
  & .label,
  & .errorMessage {
    cursor: pointer;
  }

  &.selected .label,
  &.error .label {
    @mixin hasInput;
  }

  &.active .label {
    opacity: 0;
  }
}

.optionsContainer {
  /* nb this var provided by react-aria */
  width: var(--trigger-width);
  padding: var(--spacer-40) var(--spacer-24) var(--spacer-40) var(--spacer-24);
  border: 1px solid var(--alpha-light-5, rgb(157 157 255 / 5%));
  border-radius: 10px;
  backdrop-filter: blur(125px);
  background: linear-gradient(344deg, rgb(255 255 255 / 0%) 59.57%, rgb(255 255 255 / 5%) 137.22%), rgb(157 157 255 / 5%);
  box-shadow: 25px 24px 64px rgb(0 0 0 / 80%);
  transform-origin: 50% 20%;

  --animation-duration: var(--duration-600ms);

  &[data-entering] {
    animation: expand var(--animation-duration);

    .optionsHeader,
    .options {
      animation: fade-in var(--animation-duration);
    }
  }

  &[data-exiting] {
    animation: expand var(--animation-duration) reverse var(--ease-basic-butter);

    .optionsHeader,
    .options {
      animation: fade-in var(--animation-duration) reverse var(--ease-basic-butter);
    }
  }
  @keyframes expand {
    0% {
      opacity: 0;
      transform: scale(1, 1);
    }

    10% {
      opacity: 0;

      /* nb if scale is applied at the start of the animation, it messes up the popover positioning */
      transform: scale(0, 0);
    }

    50% {
      transform: scale(1, 0);
    }

    100% {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
      transform: translateY(-8px);
    }

    /* prevents text from revealing before parent scaling is complete */
    90% {
      opacity: 0;
      transform: translateY(-8px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.optionsHeader {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: var(--spacer-28);
  margin-left: var(--spacer-24);

  & .caret {
    transform: rotate(180deg);
  }
}

.optionsTitle {
  composes: body from '~typo';
  color: var(--color-grey-100);
}

.options {
  width: 100%;
  cursor: pointer;
}

.selectedValue {
  composes: body from '~typo';
  color: var(--color-grey-90);
}

.optionItem {
  composes: button from '~typo';
  position: relative;
  overflow: hidden;
  padding: var(--spacer-16) var(--spacer-24);
  border-radius: 0.8rem;
  color: var(--color-grey-90);

  --gradient-origin: 50%;

  &:focus .optionItemGradient {
    @mixin selectedOption;
  }

  /*  this style is intentionally nested under `optionItem`.
      when an item's selected, react-aria lifts the entire node into `<SelectValue />`.
      which would otherwise include the gradient background.
  */
  .optionItemGradient {
    position: absolute;
    width: 100%;
    height: 100%;
    background: none;
    inset: 0;
    opacity: 0;
    transition: opacity var(--duration-300ms) var(--ease-default);

    &:hover {
      @mixin selectedOption;
    }
  }

  &.unset {
    font-style: italic;
    opacity: 0.8;
  }
}
