.uiButton {
  position: relative;
  display: flex;
  align-items: center;

  @media (hover: hover) {
    &:hover {
      --item-hover-progress: 1;

      path {
        fill: var(--button-hover-color);
      }
    }
  }
}

.label {
  composes: bodyMedium from '~typo';
  align-self: center;
  padding-left: var(--spacer-24);
  color: var(--color-grey-97);
  white-space: nowrap;
}

.base {
  position: relative;
  display: inline-block;
  width: var(--button-size, var(--button-height));
  height: var(--button-size, var(--button-height));
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 100%;
  cursor: pointer;

  &.sizeSmall {
    width: var(--spacer-20);
    height: var(--spacer-20);
  }

  path {
    width: 100%;
    height: 100%;
    fill: var(--button-color);
    transition: var(--duration-300ms) fill var(--ease-micro-bounce);
    transition-delay: 0.05s;
  }

  &::before {
    position: absolute;
    border: 1px solid var(--button-border-color);
    border-radius: inherit;
    background: var(--button-bg-color);
    content: '';
    inset: 0;
    opacity: calc(-1 * (var(--item-hover-progress, 0) - 1));
  }

  &::after {
    position: absolute;
    border-radius: inherit;
    background-color: var(--button-hover-bg-color);
    content: '';
    inset: 0;
    opacity: var(--item-hover-progress, 0);
    transition-duration: var(--duration-300ms);
    transition-property: opacity;
    transition-timing-function: var(--ease-micro-bounce);
  }
}

.solid {
  composes: base;

  --button-bg-color: var(--color-grey-100);
  --button-hover-bg-color: var(--color-grey-05);
  --button-color: var(--color-grey-08);
  --button-hover-color: var(--color-grey-100);
  --button-border-color: var(--color-alpha-hawkes-20);

  &::after {
    box-shadow: inset 0 0 0 0.07rem var(--button-border-color);
  }
}

.white {
  composes: base;
  composes: solid;
}

.glass {
  composes: base;

  --button-bg-color: var(--button-glass-bg);
  --button-color: var(--color-grey-100);
  --button-border-color: var(--color-alpha-light-5);
  --button-hover-border-color: var(--color-alpha-light-5);
  --button-hover-bg-color: var(--color-grey-100);
  --button-hover-color: var(--color-grey-08);
  --button-active-border-color: var(--color-alpha-light-20);
  --button-icon-bg-color: var(--button-glass-bg);

  &::before {
    backdrop-filter: blur(15px);
    box-shadow: inset 0 0 0 0.125rem var(--button-border-color);
    content: '';
  }
}

.ghost {
  composes: base;

  --button-bg-color: transparent;
  --button-color: var(--color-grey-100);
  --button-border-color: var(--color-alpha-light-20);
  --button-hover-border-color: var(--color-alpha-light-5);
  --button-hover-bg-color: var(--color-grey-100);
  --button-hover-color: var(--color-grey-08);
  --button-icon-bg-color: var(--button-glass-bg);
}

.buttonIcon {
  position: absolute;
  z-index: var(--z-index-2);
  top: 50%;
  left: 50%;
  display: flex;
  width: 54%;
  height: auto;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);

  svg {
    width: 100%;
    height: auto;
  }
}
