$fabHeight: var(--fab-height);
@value v_fabHeight: $fabHeight;

$videoWidth: 6.5rem;
@value v_videoWidth: $videoWidth;

$horizontalPadding: 0.3rem;
@value v_horizontalPadding: $horizontalPadding;

$hoverDuration: var(--duration-300ms);
$hoverEase: var(--ease-basic-butter);

.dummyFullSection {
  position: absolute;
  top: var(--fab-top-position, 0);
  left: 0;
  width: 100%;
  height: calc(100% - var(--fab-top-position, 0));
  pointer-events: none;
}

.floatingActionButtonContainer {
  /*  if the FAB is the first item in a covering section (with top rounded corners), clear the default padding that's set for the rounded corners */
  --rounded-top-padding: 0;

  /* when fab is last module and next section is "below", override the default rounded corners spacing */
  --rounded-bottom-padding: var(--spacer-84);
  position: sticky;
  z-index: var(--z-index-10);

  --fab-sticky-bottom: var(--spacer-16);
  top: calc(100vh - $fabHeight - var(--fab-sticky-bottom));
  left: 0;
  display: flex;
  width: 100%;
  justify-content: center;

  @media (--md) {
    --fab-sticky-bottom: var(--spacer-24);
  }

  @media (--lg) {
    --fab-sticky-bottom: var(--spacer-32);
  }

  /* Prevent FAB to move around if it's the last module of the page */
  &:last-child {
    position: relative;
    top: 0;
  }
}

.floatingActionButton {
  /* accomodate for the scaling in hover state so border doesn't get cut off */
  padding-bottom: 0.1rem;
  border-radius: 0.8rem;
  background: var(--color-alpha-black-100);

  @media (hover: hover) {
    &:hover {
      .hoverWrapper {
        &::before {
          background-color: var(--color-alpha-light-5);
        }
      }

      .videoPreview {
        transform: scale(1.25);
      }
    }
  }

  &:active {
    transform: translateY(2px);

    .hoverWrapper {
      &::before {
        background-color: var(--color-alpha-black-50);
      }
    }
  }
}

.hoverWrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: $horizontalPadding var(--spacer-16) $horizontalPadding $horizontalPadding;

  /* prevents flashing before animating in on mobile */
  visibility: hidden;

  &::before {
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border: 1px solid var(--color-alpha-light-10);
    border-radius: 0.8rem;
    background: var(--color-alpha-black-90) radial-gradient(713.82% 279.6% at 10% -45.83%, rgb(125 125 204 / 15%) 0%, rgb(157 157 255 / 0%) 100%);
    box-shadow: 32px 29px 25.707px 0 rgb(0 0 0 / 35%);
    content: '';
    inset: 0;

    /* transform origin x is the size of the video preview + the padding of the parent */
    transform-origin: 6.8rem center;
    transition:
      $hoverDuration transform $hoverEase,
      $hoverDuration background-color $hoverEase;
  }
}

.videoPreviewWrapper {
  position: relative;
  z-index: var(--z-index-2);
  overflow: hidden;
  width: $videoWidth;
  height: 4rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  transform-origin: calc(100% + 2rem) center;
  transition: $hoverDuration transform $hoverEase;
}

.videoPreview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform-origin: center top;
  transition: $hoverDuration transform $hoverEase;
}

.icon {
  position: absolute;
  z-index: var(--z-index-2);
  top: 50%;
  left: 50%;
  height: 1.1rem;
  fill: var(--color-alpha-light-80);
  translate: -50% -50%;
}

.label {
  z-index: var(--z-index-1);
  margin-left: var(--spacer-16);
}

.labelInnerText {
  composes: buttonFab from '~typo';
  display: block;
  transition: $hoverDuration transform $hoverEase;
  white-space: nowrap;
}

.modalPlaceholder {
  position: fixed;
  z-index: calc(var(--z-index-modal) + 1);
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 5vh 5vw;
  inset: 0;
  place-items: center;
  pointer-events: none;

  .modalCloseButton {
    position: absolute;
    z-index: var(--z-index-2);
    top: var(--spacer-40);
    right: var(--spacer-40);
    display: block;
    width: var(--spacer-40);
    height: var(--spacer-40);
    box-shadow: var(--button-box-shadow);
    opacity: 0;
  }
}

.modalBackground {
  position: absolute;
  background: var(--color-grey-00);
  inset: 0;
  opacity: 0;
}

.modalVideoPlaceholder {
  width: 100%;
  height: 100%;
  align-self: center;
  aspect-ratio: var(--aspect-ratio);
}

.modalVideoWrapper {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-alpha-light-10);
  border-radius: 1rem;
  opacity: 0;
}

.modalVideo {
  width: var(--global-max-width);
  max-width: calc(100vw - var(--grid-margin-size));
  max-height: calc(100vh - var(--grid-margin-size));
}
