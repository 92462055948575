.wrapper {
  composes: grid from '~g';
}

.inner {
  composes: subgrid from '~g';
  padding: var(--spacer-40) var(--spacer-24);
  border: solid 1px var(--color-alpha-light-10);
  border-radius: var(--border-radius-5);
  margin: 0 var(--spacer-24);
  background: var(--color-alpha-light-5);

  @media (--md) {
    padding: var(--spacer-64);
    margin: 0;
  }

  @media (--lg) {
    padding: var(--spacer-96);
  }

  &.blurredBackground {
    backdrop-filter: blur(var(--card-background-blur));
    background: var(--color-alpha-dark-50);
  }
}

.contentTextLockup {
  display: flex;
  flex-direction: column;

  /* title4block applies a dash for all em's which is probably not correct but don't want to run through the whole validation right now, trying to get this one out */
  em::before {
    display: none;
  }

  p {
    margin-top: var(--spacer-16);
  }
}

.form {
  composes: subgrid from '~g';
  grid-column: start / end;
}

.row {
  composes: subgrid from '~g';
  grid-column: start / end;
}

.headingWrapper {
  grid-column: start / end;
}

.submitRow {
  composes: subgrid from '~g';
  align-items: start;
  margin-top: var(--spacer-48);
  grid-column: span 12;
}

.disclaimerButton {
  grid-column: start / end;
  grid-row: 1;

  @media (--md) {
    margin-bottom: 0;
    grid-column: start / span 7;
  }
}

.disclaimerContent {
  margin-bottom: var(--spacer-48);
  color: var(--color-alpha-light-60);
  grid-column: start / end;
  grid-row: 1;

  @media (--md) {
    margin-bottom: 0;
    grid-column: start / span 7;
  }

  &.requiresConsent {
    grid-row: 2;

    @media (--lg) {
      padding-left: var(--spacer-64);
      grid-column: start / span 8;
    }
  }

  &.error {
    color: var(--color-coral);
  }
}

.submitWrapper {
  grid-column: start / end;
  grid-row: 3;
  text-align: right;

  @media (--md) {
    grid-column: span 5 / end;
    grid-row: 1;
  }

  @media (--xl) {
    grid-column: span 3 / end;
  }
}

.submit {
  width: 100%;
  @media (--md) {
    width: auto;
  }

  &[disabled] {
    cursor: not-allowed;
  }
}

.titleSpan {
  display: block;
}

.confirmationScreenContent {
  grid-column: start / end;

  --custom-icon-size: 4rem;
  --button-border-radius: 0.5rem;

  /* Equal width buttons */
  .ctas {
    @media (--md) {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: repeat(var(--nb-buttons), minmax(auto, 1fr));
      justify-self: center;
    }
  }
}
