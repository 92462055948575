@import '../FormInput.module.css';

.textInput {
  composes: body from '~typo';
  position: relative;
  height: var(--spacer-48);
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid var(--border-color);
  background: inherit;
  color: var(--color-grey-90);
  transition: border-color var(--duration-300ms) var(--ease-default);

  --border-color: var(--color-grey-20);
  --gradient-origin: 50%;

  &:focus {
    /* Adding !important here because juggling with specificity is quite complicated with all the
		composition between inputs (the error state of the select dropdowns was overriding the focus
		state */
    /* stylelint-disable-next-line declaration-no-important */
    --border-color: var(--color-grey-100) !important;

    & + .label {
      @mixin hasInput;
    }
  }

  /* override autofill styles */
  &:autofill,
  &[data-com-onepassword-filled='dark'],
  &[data-com-onepassword-filled='light'] {
    /* https://stackoverflow.com/a/64166144 */
    background-clip: text;
    background-color: transparent;

    /* https://stackoverflow.com/a/72205360 */
    -webkit-text-fill-color: var(--color-grey-90);
  }
}

.error {
  composes: error from '../FormInput.module.css';
}

.required {
  composes: required from '../FormInput.module.css';
}

.label {
  composes: label from '../FormInput.module.css';
}

.labelInner {
  composes: labelInner from '../FormInput.module.css';
}

.errorMessage {
  composes: errorMessage from '../FormInput.module.css';
}

.mask {
  composes: mask from '../FormInput.module.css';
}

/* truthy input styling */
.textInput[type='text']:not([value='']),
.textInput[type='tel']:not([value='']),
.textInput[type='email']:not([value='']),
.error .textInput {
  & + .label {
    @mixin hasInput;
  }
}
