.circleLoader {
  position: relative;
  width: 7.3rem;
  height: 7.3rem;

  .progressCircle {
    width: 100%;
    height: 100%;
  }

  .progressBar {
    stroke-dasharray: 180;
    stroke-dashoffset: calc((1 - var(--progress, 0)) * 180);
    transform: rotate(-90deg);
    transform-origin: 50%;
  }

  .progressValue {
    position: absolute;
    top: 50%;
    left: 50%;
    color: var(--color-grey-60);
    font-family: var(--font-tertiary);
    font-size: 1.3rem;
    line-height: 1.42;
    transform: translate3d(-50%, -50%, 0);

    strong {
      color: var(--color-grey-90);
      font-size: 1.6rem;
    }
  }
}
