/* checkboxGroup */

.row {
  composes: subgrid from '~g';
  grid-column: start / end;
}

.checkboxGroupContainer {
  display: block;

  & .label {
    position: initial;
    display: inline-flex;
    margin-bottom: var(--spacer-8);
  }
}

.label {
  composes: label from '../FormInput.module.css';
}

.required {
  composes: required from '../FormInput.module.css';
}

.error {
  composes: error from '../FormInput.module.css';

  .checkbox {
    --border-color: var(--color-coral);

    &:hover {
      .checkboxMask::after {
        opacity: 0;
      }
    }
  }
}

.errorMessage {
  composes: errorMessage from '../FormInput.module.css';
}

.checkboxLabel {
  grid-column: start / end;

  &:not(:last-child) {
    margin-bottom: var(--spacer-12);
  }

  @media (--md) {
    grid-column: span 6;
  }

  @media (--lg) {
    grid-column: span 3;
  }
}

.checkbox {
  --border-color: transparent;
  composes: body from '~typo';
  position: relative;
  display: flex;
  height: var(--spacer-54);
  flex-direction: row;
  align-items: center;
  padding: var(--spacer-16);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-8);
  background: var(--color-alpha-light-5);
  color: var(--color-grey-100);
  cursor: pointer;
  transition: all var(--duration-300ms) var(--ease-default);

  @media (--lg) {
    margin-bottom: 0;
  }

  [data-selected='true'] & {
    background: var(--color-cobalt);

    &:hover {
      .checkboxMask::after {
        opacity: 0;
      }
    }
  }

  [data-focused='true'] & {
    --border-color: var(--color-grey-100);

    .checkboxMask::after {
      opacity: 0;
    }
  }

  &:hover .checkboxMask::after {
    opacity: 1;
  }

  --gradient-origin-x: 50%;
  --gradient-origin-y: 50%;
}

.checkboxMask {
  position: absolute;
  inset: -0.2rem;
  mask-image: url('/form-input-mask.svg');
  pointer-events: none;

  &::after {
    position: absolute;
    z-index: -1;
    background: radial-gradient(circle, var(--color-grey-100), rgb(252 252 252 / 0%));
    content: '';
    inset: 0;
    opacity: 0;
    transform: translate3d(calc(var(--gradient-origin-x) - 50%), 0, 0);
    transition: opacity var(--duration-300ms) var(--ease-default);

    @media (hover: none) {
      display: none;
      content: none;
    }
  }
}

.checkboxIcon {
  --border-color: var(--color-grey-60);
  display: flex;
  width: var(--spacer-24);
  height: var(--spacer-24);
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-color: var(--border-color);
  border-radius: 4px;
  margin-right: var(--spacer-16);

  & svg {
    display: block;
    width: 1.2rem;
    opacity: 0;
    transition: opacity var(--duration-300ms) var(--ease-default);
  }

  [data-selected='true'] & {
    border-color: var(--color-grey-100);
    background: var(--color-grey-100);

    & svg {
      fill: var(--color-cobalt);
      opacity: 1;
    }
  }
}
