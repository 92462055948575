/* stylelint-disable function-parentheses-space-inside */
/* stylelint-disable length-zero-no-unit */
.shadowWrapper {
  position: relative;

  &.standalone {
    position: absolute;
    z-index: var(--z-index-neg);
    inset: 0;
  }

  --default-shadow-scale: 1;
  --shadow-bottom-height: calc(var(--shadow-scale, var(--default-shadow-scale)) * 250px);
  --shadow-right-height: calc(var(--shadow-scale, var(--default-shadow-scale)) * 210px);
  --shadow-bottom-width: calc(var(--shadow-scale, var(--default-shadow-scale)) * 195px);
  --shadow-right-width: calc(var(--shadow-scale, var(--default-shadow-scale)) * 0px);
  --shadow-bottom-left-offset: calc(var(--shadow-scale, var(--default-shadow-scale)) * -1px);
  --shadow-bottom-top-offset: calc(30px + var(--shadow-radius, 0px) / 3);
  --shadow-right-offset: calc(var(--shadow-scale, var(--default-shadow-scale)) * 55px - var(--shadow-radius, 0px) / 2);
  --shadow-bottom-right-offset: calc(var(--shadow-right-width) - var(--shadow-scale, var(--default-shadow-scale)) * -140px + var(--shadow-radius, 0px) / 2);
}

.shadow {
  position: absolute;
  z-index: var(--z-index-0);
  inset: 0;
  pointer-events: none;
}

.bottom {
  position: absolute;
  top: calc(100% - var(--shadow-scale, var(--default-shadow-scale)) * var(--shadow-bottom-top-offset));
  left: calc(-1 * var(--shadow-bottom-left-offset));
  display: flex;
  width: calc(100% + var(--shadow-bottom-right-offset) + var(--shadow-bottom-left-offset));
  height: var(--shadow-bottom-height);
}

.right {
  position: absolute;
  top: 0;
  left: calc(100% - var(--shadow-right-offset));
  display: flex;
  width: var(--shadow-right-width);
  height: calc(100% - var(--shadow-scale, var(--default-shadow-scale)) * var(--shadow-bottom-top-offset));
  flex-direction: column;
}

.topRight {
  height: min(100%, var(--shadow-right-height));
  flex-grow: 0;
}

.middleRight {
  position: relative;
  flex-grow: 1;
}

.topRight,
.middleRight {
  position: relative;
  overflow: hidden;
  width: var(--shadow-bottom-width);
}

.middleRightImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bottomLeft,
.bottomMiddle,
.bottomRight {
  position: relative;
  overflow: hidden;
  height: calc(var(--shadow-bottom-height));
}

.bottomLeft {
  width: var(--shadow-bottom-width);
  flex-grow: 0;
}

.bottomMiddle {
  position: relative;
  flex-grow: 1;
}

.bottomRight {
  width: var(--shadow-bottom-width);
  flex-grow: 0;
}

.shadowImage {
  height: 100%;
}
