.disclamerInputContainer {
  composes: inputContainer from '../FormInput.module.css';
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
}

.switchContainer {
  composes: body from '~typo';
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--spacer-16) 0;
  color: var(--color-grey-100);
  cursor: pointer;

  --border-color: transparent;

  &:not(.disclaimerLayout) {
    justify-content: space-between;
    padding: var(--spacer-16);
    border: 1px solid var(--border-color);
    border-radius: var(--spacer-8);
    background: var(--color-alpha-light-5);
    transition: border-color var(--duration-300ms) var(--ease-default);
  }

  &[data-focused='true'] {
    border-color: var(--color-grey-100);

    &:not(.disclaimerLayout):hover {
      .checkboxMask::after {
        opacity: 0;
      }
    }
  }

  .checkboxMask {
    height: 5.9rem;
  }

  &:not(.disclaimerLayout)&:hover .checkboxMask::after {
    opacity: 1;
  }
}

.checkboxMask {
  composes: checkboxMask from '../CheckboxGroup/CheckboxGroup.module.css';
}

.errorMessage {
  composes: errorMessage from '../FormInput.module.css';
  display: inline-block;
}

.error {
  composes: error from '../FormInput.module.css';
}

.label {
  composes: label from '../FormInput.module.css';
  bottom: auto;
  margin-left: var(--spacer-20);
}

.required {
  composes: required from '../FormInput.module.css';
}

.mask {
  composes: mask from '../FormInput.module.css';
}

.switch {
  position: relative;
  width: var(--spacer-48);
  height: var(--spacer-24);
  border-radius: var(--spacer-24);
  margin-left: auto;
  background: var(--color-grey-60);
  transition: all var(--duration-300ms) var(--ease-default);

  [data-selected='true'] & {
    background: var(--color-cobalt);

    &::after {
      transform: translateX(calc(var(--spacer-48) - var(--handle-height) - 4px));
    }
  }

  &::after {
    --handle-height: 20px;
    position: absolute;
    top: calc((var(--spacer-24) - var(--handle-height)) / 2);
    left: 2px;
    width: var(--handle-height);
    height: var(--handle-height);
    border-radius: var(--spacer-24);
    background: var(--color-grey-100);
    content: '';
    transform: translateX(0);
    transition: transform var(--duration-60ms) var(--ease-micro-bounce);
  }
}

.disclaimerLayout {
  .switch {
    order: 1;
    margin-left: 0;
  }

  .label {
    position: relative;
    order: 2;
    margin-left: var(--spacer-18);
  }
}
