.hero {
  --covered-module-negative-bottom-margin: var(--parallax-start-y);
  position: relative;
  width: 100%;
  padding-bottom: 0;
  background-color: var(--color-grey-00);
}

.container {
  composes: grid from '~g';
  position: relative;
  z-index: var(--z-index-1);

  /* accomodates for bottom browser bar in ios safari */
  min-height: calc(100svh + var(--spacer-28));
  align-content: end;
  padding-top: var(--spacer-96);
  padding-bottom: var(--default-rounded-bottom-padding);
  row-gap: 5.6rem;

  @media (--md) {
    min-height: calc(100vh + var(--spacer-28));
    padding-top: var(--spacer-32);
  }

  @media (--xl) {
    padding-bottom: calc(var(--default-rounded-bottom-padding) * var(--xl-scale-value));
  }

  &.isCoveredAndBottom {
    padding-bottom: var(--spacer-64);

    @media (--md) {
      padding-bottom: var(--spacer-96);
    }

    @media (--lg) {
      padding-bottom: var(--spacer-124);
    }
  }

  &.organicHeight {
    min-height: unset;
    padding: calc(var(--spacer-64) + var(--nav-height)) 0 var(--spacer-64);

    @media (--md) {
      padding: calc(var(--spacer-96) + var(--nav-height)) 0 var(--spacer-124);
    }
  }
}

.left {
  .content {
    @media (--lg) {
      /* negatively set the hero text so that the space between it and the left side of the viewport is 124px */
      left: calc(-1 * (var(--grid-computed-margin-width) - var(--spacer-124)));
    }

    @media (--xl) {
      /* negatively set the hero text so that the space between it and the left side of the viewport is 124px * 1.145 */
      left: calc(-1 * (var(--grid-computed-margin-width) - (var(--spacer-124) * 1.145)));
    }
  }
}

.middle {
  .container {
    align-content: center;
  }
}

.top {
  .container {
    align-content: start;
  }
}

.bottom {
  .container {
    align-content: flex-end;
  }
}

.hero .backgroundWrapper {
  position: absolute;
  z-index: var(--z-index-0);
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;

  --z-index-gradient-overlay: var(--z-index-3);

  .backgroundImageContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    object-fit: cover;
    transition: var(--duration-600ms) opacity var(--ease-basic-butter);
    will-change: transform;

    &.isHidden {
      opacity: 0;
    }
  }

  .bottomGlow {
    position: absolute;
    z-index: var(--z-index-neg);
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20rem;
    transform: scaleX(1.5) rotate(-10deg);
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.hasFloatingVideoPreview {
  .content {
    grid-row: 2;

    @media (--md) {
      grid-row: unset;
    }
  }

  .floatingVideoPreview {
    margin-top: 0;
    grid-column: start/end;
    grid-row: 3;

    --preview-video-aspect-ratio: 1.7;
    @media (--md) {
      margin-top: 0;
      grid-column: unset;
    }
  }
}
