/* inputs - shared */
.inputContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: var(--spacer-8);
  margin-top: var(--spacer-48);

  &.required .label::after {
    position: absolute;
    top: -4px;
    right: -10px;
    color: var(--color-cobalt);
    content: '*';
  }

  &.error {
    .textInput,
    .label,
    .label::after,
    .trigger,
    .checkbox,
    .checkboxIcon {
      --border-color: var(--color-coral);
      color: var(--color-coral);
    }

    .caret svg {
      fill: var(--color-coral);
    }
  }

  &:hover .mask::after {
    opacity: 1;
  }
}

.error {
  .textInput,
  .label,
  .label::after,
  .trigger,
  .checkbox,
  .checkboxIcon {
    --border-color: var(--color-coral);
    color: var(--color-coral);
    content: '';
  }

  .caret svg {
    fill: var(--color-coral);
  }
}

.span3,
.span4,
.span6,
.span8,
.span12 {
  grid-column: 1 / span 12;
}

.span3 {
  @media (--md) {
    grid-column: span 3;
  }
}

.span4 {
  @media (--md) {
    grid-column: span 4;
  }
}

.span6 {
  @media (--md) {
    grid-column: span 6;
  }
}

.span8 {
  @media (--md) {
    grid-column: span 8;
  }
}

.span12 {
  @media (--md) {
    grid-column: 1 / span 12;
  }
}

.label {
  composes: formLabel from '~typo';
  position: absolute;
  bottom: 2.2rem;
  left: 0;
  color: var(--color-alpha-light-60);
  cursor: text;
  font-size: 1.6rem;
  pointer-events: none;
  transform: translateY(0);
  transition: all var(--duration-300ms) var(--ease-default);
  transition-delay: 10ms;
}

.labelInner {
  position: relative;
  display: flex;
}

.errorMessage {
  composes: eyebrow from '~typo';
  font-size: inherit;
  pointer-events: none;

  &::before {
    padding: 0 var(--spacer-4);
    content: '—';
  }

  .textInput & {
    display: flex;
  }
}

@define-mixin hasInput {
  /* selectContainer has left-margin in resting state to accomodate caret */
  margin-left: initial;
  cursor: default;
  font-size: 1.2rem;
  transform: translateY(-3.2rem);

  /* hide required indicator */
  &::after {
    content: '';
  }
}

/* gradient border underline */
.mask {
  position: absolute;
  min-height: var(--spacer-48);
  inset: 0 0 var(--spacer-8);
  mask-image: url('/form-input-mask-underline.svg');
  pointer-events: none;

  --gradient-origin-x: 50%;
  --gradient-origin-y: 50%;

  [data-selected='true'] &,
  .error & {
    &:hover::after {
      opacity: 0;
    }
  }

  &::after {
    position: absolute;
    z-index: -1;
    background: radial-gradient(circle, var(--color-grey-100), rgb(252 252 252 / 0%));
    content: '';
    inset: 0;
    opacity: 0;
    transform: translate3d(calc(var(--gradient-origin-x) - 50%), 0, 0);
    transition: opacity var(--duration-300ms) var(--ease-default);

    @media (hover: none) {
      display: none;
      content: none;
    }
  }
}
