.heroCard {
  overflow: hidden;
  width: var(--hero-card-width);
  border-radius: var(--border-radius-10);

  /* custom gradient to fake blur */
  background: linear-gradient(90deg, #1f1e1b 0%, #1a1a19 32.48%, #1f1f1b 51.59%, #25261e 99.98%);
  will-change: transform;

  /* black layer, mobile only */
  &::before {
    position: absolute;
    border: 0.15rem solid var(--color-alpha-light-10);
    background-color: var(--color-grey-00);
    content: '';
    inset: 0;
    opacity: calc(1 - var(--card-content-opacity, 1));
  }

  @media (--md) {
    border: 0.15rem solid var(--color-alpha-light-10);
    backdrop-filter: blur(30px);
    background: var(--color-alpha-dark-40);

    &::before {
      display: none;
      opacity: 0;
    }
  }

  .cardMediaWrapper {
    position: relative;
    opacity: var(--card-content-opacity, 1);

    /* Vapor-like fake checkbox */
    &::after {
      position: absolute;
      z-index: var(--z-index-2);
      top: 1.05rem;
      left: 0.84rem;
      display: block;
      width: 1.68rem;
      height: 1.68rem;
      border-radius: 0.4rem;
      background-color: rgb(255 255 255 / 15%);
      content: '';
    }

    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .mediaInnerWrapper {
    width: 100%;

    --image-aspect-ratio: 380/213;

    .shouldAnimate & {
      clip-path: circle(0%);
    }
  }

  .mediaInnerWrapperInner {
    aspect-ratio: var(--image-aspect-ratio);
  }

  /* Vapor-like: not using our variables since it's mimicking the app */
  .chin {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0.8rem;
    font-family: var(--font-tertiary);
    font-weight: 400;
    gap: 0.3rem;
    opacity: var(--card-content-opacity, 1);

    @media (--md) {
      padding: 1.3rem;
      gap: 0.4rem;
    }

    .lowerChin {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .title {
      font-size: 0.9rem;
      font-weight: 500;
      line-height: 1.42;

      @media (--md) {
        font-size: 1.4rem;
      }
    }

    .author {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      &::after {
        display: inline-block;
        width: 0.3rem;
        height: 0.3rem;
        border-radius: 50%;
        margin: 0 0.8rem;
        background-color: var(--color-grey-70);
        content: '';
      }
    }

    .subtitle {
      color: var(--color-alpha-light-50);
      font-size: 0.8rem;
      line-height: 1.42;
      @media (--md) {
        font-size: 1.3rem;
      }
    }

    .more {
      width: 1.1rem;
      height: 1.1rem;
      @media (--md) {
        width: 1.7rem;
        height: 1.7rem;
      }
    }
  }
}
