.faqItem {
  .answer {
    @media (--md) {
      padding: 0;
    }
  }
}

.question {
  composes: title6 from '~typo';
}

.questionIcon {
  width: 1.6rem;
  height: 1.6rem;
}

.answer {
  composes: body from '~typo';
  color: var(--color-grey-60);
}

.portableText {
  composes: subgrid from '~g';

  @media (--md) {
    --subgrid-start: col 2;
    --subgrid-width: calc(var(--grid-number-of-columns) - 2);
  }

  p {
    color: var(--grey-brand-grey-60, #81859f);
  }

  .paragraph {
    margin-bottom: var(--spacer-16);
    grid-column: start / end;

    @media (--md) {
      grid-column: start / span 9;
    }

    @media (--lg) {
      grid-column: start / span 7;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & > * {
    &:last-child {
      margin-bottom: initial;
    }
  }
}
