.portableText {
  position: relative;

  br {
    display: none;

    @media (--lg) {
      display: block;
    }
  }
}

/* typography */

.title1 {
  composes: title1 from '~typo';
}

.title2 {
  composes: title2 from '~typo';
}

.title3 {
  composes: title3 from '~typo';
}

.title4 {
  composes: title4 from '~typo';
}

.title5 {
  composes: title5 from '~typo';
}

.title55 {
  composes: title55 from '~typo';
}

.title6 {
  composes: title6 from '~typo';
}

.ribbon {
  composes: ribbon from '~typo';
}

.formDisclaimer {
  composes: formDisclaimer from '~typo';
}

.body {
  composes: body from '~typo';
}

/* TODO: delete once v4 design system is fully implemented, not used in v4 */
.eyebrowSmall {
  composes: eyebrowSmall from '~typo';
}

.eyebrow {
  composes: eyebrow from '~typo';
}

.em {
  composes: em from '~typo';
}

.label {
  composes: label from '~typo';
}

.strong {
  composes: strong from '~typo';
}

.link {
  composes: inlineLink from '~typo';
}

.footnote {
  composes: footnote from '~typo';
}

.list {
  display: block;

  li {
    strong {
      color: var(--color-grey-80);
    }
  }
}

.bullet,
.number {
  list-style: initial;
}

/* stylelint-disable-next-line selector-class-pattern */
.u {
  text-decoration: underline;
}
