/* references the 600px breakpoint of the grid that has adjusted margins */
/* breakpoint to switch from mobile to desktop nav */

/* Font weights mapping, based on https: //developer.mozilla.org/en-US/docs/Web/CSS/font-weight#common_weight_name_mapping */
.typography_thin__xR6mo {
  font-weight: 100;
}
.typography_hairline__6OHM5 {
}
.typography_extraLight__F0k7d {
  font-weight: 200;
}
.typography_ultraLight__AlcWp {
}
.typography_light__2T4By {
  font-weight: 300;
}
.typography_normal__sJIx6 {
  font-weight: 400;
}
.typography_regular__7Oop_ {
}
.typography_medium__ZboWQ {
  font-weight: 500;
}
.typography_semiBold__ljTNh {
  font-weight: 600;
}
.typography_demiBold__PhAWd {
}
.typography_bold__5qkbA {
  font-weight: 700;
}
.typography_extraBold__5DbAa {
  font-weight: 800;
}
.typography_ultraBold__ELVue {
}
.typography_black__5nFNo {
  font-weight: 900;
}
.typography_heavy__lIJmD {
}
.typography_extraBlack__TsI5W {
  font-weight: 950;
}
.typography_ultraBlack___a__Q {
}
.typography_title1__Na5gU {
  font-family: var(--font-main, sans-serif);
  font-size: 4.4rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.03em;
  line-height: 1.1;
  text-wrap: var(--text-wrap, initial);
}
@media (min-width: 768px) {
.typography_title1__Na5gU {
    font-size: 5.6rem;
    letter-spacing: -0.045em;
    line-height: 1.04
}
  }
@media (min-width: 1240px) {
.typography_title1__Na5gU {
    font-size: 8rem;
    letter-spacing: -0.045em;
    line-height: 1.04
}
  }
.typography_title2__Ry8_z {
  font-family: var(--font-main, sans-serif);
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.03em;
  line-height: 1.15;
  text-wrap: var(--text-wrap, initial);
}
@media (min-width: 768px) {
.typography_title2__Ry8_z {
    font-size: 3.8rem;
    letter-spacing: -0.04em;
    line-height: 1.04
}
  }
@media (min-width: 1240px) {
.typography_title2__Ry8_z {
    font-size: 4.8rem;
    line-height: 1.02
}
  }
.typography_title3___zRGC {
  font-family: var(--font-main, sans-serif);
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.015em;
  line-height: 1.2;
  text-wrap: var(--text-wrap, initial);
}
@media (min-width: 768px) {
.typography_title3___zRGC {
    font-size: 3rem;
    letter-spacing: -0.035em;
    line-height: 1.2
}
  }
@media (min-width: 1240px) {
.typography_title3___zRGC {
    font-size: 3.8rem;
    line-height: 1.16
}
  }
.typography_title4__uIGjC {
  font-family: var(--font-main, sans-serif);
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.015em;
  line-height: 1.2;
  text-wrap: var(--text-wrap, initial);
}
@media (min-width: 768px) {
.typography_title4__uIGjC {
    letter-spacing: -0.03em;
    line-height: 1.12
}
  }
@media (min-width: 1240px) {
.typography_title4__uIGjC {
    font-size: 2.5rem;
    line-height: 1.12
}
  }
.typography_title5__xlC2_ {
  font-family: var(--font-main, sans-serif);
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.005em;
  line-height: 1.33;
  text-wrap: var(--text-wrap, initial);
}
@media (min-width: 768px) {
.typography_title5__xlC2_ {
    font-size: 1.7rem;
    letter-spacing: -0.025em;
    line-height: 1.23
}
  }
@media (min-width: 1240px) {
.typography_title5__xlC2_ {
    font-size: 2rem;
    letter-spacing: -0.025em;
    line-height: 1.23
}
  }
.typography_title55___yY_Y {
  font-family: var(--font-main, sans-serif);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.2;
  text-wrap: var(--text-wrap, initial);
}
@media (min-width: 768px) {
.typography_title55___yY_Y {
    font-size: 1.6rem
}
  }
@media (min-width: 1240px) {
.typography_title55___yY_Y {
    font-size: 1.8rem
}
  }
.typography_title6__X5iGE {
  font-family: var(--font-main, sans-serif);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.3;
  text-wrap: var(--text-wrap, initial);
}
.typography_titleStatistic__QgUty {
  font-family: var(--font-main, sans-serif);
  font-size: 7.2rem;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0;

  /* needs to cap vertical top and bottom space - each font is different
    and this font seems to cap at this relative measurement */
  line-height: 0.72;
}
@media (min-width: 768px) {
.typography_titleStatistic__QgUty {
    font-size: 6rem
}
  }
@media (min-width: 1240px) {
.typography_titleStatistic__QgUty {
    font-size: 8rem
}
  }
.typography_body__vVxn5 {
  color: var(--color-alpha-light-50);
  font-family: var(--font-main, sans-serif);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.45;
}
.typography_bodyMedium__QIIIF {
  color: var(--color-alpha-light-50);
  font-family: var(--font-main, sans-serif);
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.025em;
  line-height: 1.35;
}
@media (min-width: 768px) {
.typography_bodyMedium__QIIIF {
    font-size: 1.7rem;
    letter-spacing: 0;
    line-height: 1.45
}
  }
@media (min-width: 1240px) {
.typography_bodyMedium__QIIIF {
    font-size: 1.8rem;
    line-height: 1.45
}
  }
.typography_bodyLarge__cu4q_ {
  color: var(--color-alpha-light-50);
  font-family: var(--font-main, sans-serif);
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
}
@media (min-width: 768px) {
.typography_bodyLarge__cu4q_ {
    font-size: 2.2rem;
    line-height: 1.3
}
  }
@media (min-width: 1240px) {
.typography_bodyLarge__cu4q_ {
    font-size: 2.4rem;
    letter-spacing: 0
}
  }
.typography_bodyExtraLarge__sUWvZ {
  color: var(--color-alpha-light-50);
  font-family: var(--font-main, sans-serif);
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.015em;
  line-height: 1.2;
}
@media (min-width: 768px) {
.typography_bodyExtraLarge__sUWvZ {
    font-size: 2.8rem;
    letter-spacing: -0.035em;
    line-height: 1.1
}
  }
@media (min-width: 1240px) {
.typography_bodyExtraLarge__sUWvZ {
    font-size: 3.2rem;
    letter-spacing: 0
}
  }
.typography_eyebrow__vJy3S {
  font-family: var(--font-secondary, monospace);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.06em;
  line-height: 0.9;
  text-transform: uppercase;
}
.typography_eyebrowSmall__d8C_k {
  font-family: var(--font-main, sans-serif);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.6;
}
.typography_button__Uyx6e {
  font-family: var(--font-main, sans-serif);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 0.96;
}
.typography_label__CkNI3 {
  font-family: var(--font-main, sans-serif);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.25;
}
@media (min-width: 1240px) {
.typography_label__CkNI3 {
    font-size: 1.6rem
}
  }
.typography_inputError__Sh484 {
  font-family: var(--font-main, sans-serif);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1;
}
.typography_analyticsBanner___LHzp {
  font-family: var(--font-main, sans-serif);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.35;
}
.typography_navigation__70PKN {
}
@media (min-width: 900px ) {
.typography_navigation__70PKN {
    font-family: var(--font-main, sans-serif);
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.01em;
    line-height: 0.96
}
  }
.typography_subnavigation__LWso6 {
}
.typography_reducedNavigation__4_qzl {
  font-family: var(--font-main, sans-serif);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 0.96;
}
@media (min-width: 1158px ) {
.typography_reducedNavigation__4_qzl {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 0.96
}
  }
.typography_announcementBanner__3mJoF {
  font-family: var(--font-main, sans-serif);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 1;
}
.typography_strong__UFdiB {
  font-weight: 600;
}
.typography_stats__WhQk1 {
  font-family: var(--font-main, sans-serif);
  font-size: 8.8rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.03em;
  line-height: 0.95;
}
@media (min-width: 768px) {
.typography_stats__WhQk1 {
    font-size: 5.4rem;
    letter-spacing: -0.04em;
    line-height: 1.04
}
  }
@media (min-width: 1240px) {
.typography_stats__WhQk1 {
    font-size: 7.2rem;
    line-height: 1.02
}
  }
.typography_hugeStats__aPFBH {
  font-family: var(--font-main, sans-serif);
  font-size: 8.8rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.03em;
  line-height: 0.95;
}
@media (min-width: 768px) {
.typography_hugeStats__aPFBH {
    font-size: 16rem;
    letter-spacing: -0.04em;
    line-height: 1.02
}
  }
@media (min-width: 1240px) {
.typography_hugeStats__aPFBH {
    font-size: 20rem;
    line-height: 1.02
}
  }
.typography_hugeStatsLabel__pNUpH {
  font-family: var(--font-main, sans-serif);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.35;
}
@media (min-width: 768px) {
.typography_hugeStatsLabel__pNUpH {
    font-size: 1.8rem;
    letter-spacing: 0
}
  }
@media (min-width: 1240px) {
.typography_hugeStatsLabel__pNUpH {
    font-size: 2rem;
    line-height: 1.45
}
  }
.typography_statsSuffix__YHMP7 {
  /* title 2 */
  font-family: var(--font-main, sans-serif);
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.03em;
  line-height: 1.15;
}
/* title 4 */
@media (min-width: 768px) {
.typography_statsSuffix__YHMP7 {
    letter-spacing: -0.03em;
    line-height: 1.12
}
  }
@media (min-width: 1240px) {
.typography_statsSuffix__YHMP7 {
    font-size: 2.5rem;
    line-height: 1.12
}
  }
.typography_hugeStatsSuffix__eI1Nr {
  /* title 2 */
  font-family: var(--font-main, sans-serif);
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.03em;
  line-height: 1.15;
}
/* title 1 */
@media (min-width: 768px) {
.typography_hugeStatsSuffix__eI1Nr {
    font-size: 5.6rem;
    letter-spacing: -0.045em;
    line-height: 1.04
}
  }
@media (min-width: 1240px) {
.typography_hugeStatsSuffix__eI1Nr {
    font-size: 8rem
}
  }
.typography_pricingOverviewCardPrice__hbenv {
  font-family: var(--font-main, sans-serif);
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.09rem;
  line-height: 1.1;
}
@media (min-width: 768px) {
.typography_pricingOverviewCardPrice__hbenv {
    font-size: 3rem;
    line-height: 1.06
}
  }
@media (min-width: 1240px) {
.typography_pricingOverviewCardPrice__hbenv {
    font-size: 3rem;
    line-height: 1.02
}
  }
.typography_pricingOverviewCardCustomLabel__lpQ3H {
  font-family: var(--font-main, sans-serif);
  font-size: 4.8rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.03em;
  line-height: 1.15;
}
@media (min-width: 768px) {
.typography_pricingOverviewCardCustomLabel__lpQ3H {
    font-size: 5.4rem;
    letter-spacing: -0.04em;
    line-height: 1.04
}
  }
@media (min-width: 1240px) {
.typography_pricingOverviewCardCustomLabel__lpQ3H {
    font-size: 4.8rem;
    line-height: 1.16
}
  }
.typography_pricingCardBulletList__Bll9r {
  font-family: var(--font-main, sans-serif);
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.45;
}
.typography_inlineLink__40h2E {
  display: inline-block;
  color: var(--color-grey-88);
  text-decoration: underline;
}
.typography_inlineLink__40h2E:hover {
    text-decoration: none;
  }
.typography_formLabel__T92ll {
  font-family: var(--font-main, sans-serif);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25;
}
.typography_ribbon__jnHdO {
  font-family: var(--font-main, sans-serif);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.12;
}
@media (min-width: 768px) {
.typography_ribbon__jnHdO {
    line-height: 0.96
}
  }
.typography_formDisclaimer__WbKTP {
  font-family: var(--font-main, sans-serif);
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25;
}
.typography_footnote__h8r_U {
  font-family: var(--font-main, sans-serif);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.typography_buttonFab___dBLA {
  font-family: var(--font-main, sans-serif);
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 0.96;
}
.typography_em__tS1fk {
  color: var(--em-color, var(--color-alpha-light-50));
}

.UIButtonBase_uiButton__O5h6Y {
  position: relative;
  display: flex;
  align-items: center;
}

  @media (hover: hover) {
    .UIButtonBase_uiButton__O5h6Y:hover {
      --item-hover-progress: 1;
    }

      .UIButtonBase_uiButton__O5h6Y:hover path {
        fill: var(--button-hover-color);
      }
  }

.UIButtonBase_label__vJWzV {
  align-self: center;
  padding-left: var(--spacer-24);
  color: var(--color-grey-97);
  white-space: nowrap;
}

.UIButtonBase_base__ZHtYL {
  position: relative;
  display: inline-block;
  width: var(--button-size, var(--button-height));
  height: var(--button-size, var(--button-height));
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 100%;
  cursor: pointer;
}

.UIButtonBase_base__ZHtYL.UIButtonBase_sizeSmall___gsGC {
    width: var(--spacer-20);
    height: var(--spacer-20);
  }

.UIButtonBase_base__ZHtYL path {
    width: 100%;
    height: 100%;
    fill: var(--button-color);
    transition: var(--duration-300ms) fill var(--ease-micro-bounce);
    transition-delay: 0.05s;
  }

.UIButtonBase_base__ZHtYL::before {
    position: absolute;
    border: 1px solid var(--button-border-color);
    border-radius: inherit;
    background: var(--button-bg-color);
    content: '';
    inset: 0;
    opacity: calc((var(--item-hover-progress, 0) - 1)*-1);
  }

.UIButtonBase_base__ZHtYL::after {
    position: absolute;
    border-radius: inherit;
    background-color: var(--button-hover-bg-color);
    content: '';
    inset: 0;
    opacity: var(--item-hover-progress, 0);
    transition-duration: var(--duration-300ms);
    transition-property: opacity;
    transition-timing-function: var(--ease-micro-bounce);
  }

.UIButtonBase_solid__vkASw {

  --button-bg-color: var(--color-grey-100);
  --button-hover-bg-color: var(--color-grey-05);
  --button-color: var(--color-grey-08);
  --button-hover-color: var(--color-grey-100);
  --button-border-color: var(--color-alpha-hawkes-20);
}

.UIButtonBase_solid__vkASw::after {
    box-shadow: inset 0 0 0 0.07rem var(--button-border-color);
  }

.UIButtonBase_white__0nDCL {
}

.UIButtonBase_glass__e2ods {

  --button-bg-color: var(--button-glass-bg);
  --button-color: var(--color-grey-100);
  --button-border-color: var(--color-alpha-light-5);
  --button-hover-border-color: var(--color-alpha-light-5);
  --button-hover-bg-color: var(--color-grey-100);
  --button-hover-color: var(--color-grey-08);
  --button-active-border-color: var(--color-alpha-light-20);
  --button-icon-bg-color: var(--button-glass-bg);
}

.UIButtonBase_glass__e2ods::before {
    -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px);
    box-shadow: inset 0 0 0 0.125rem var(--button-border-color);
    content: '';
  }

.UIButtonBase_ghost__Rg9O1 {

  --button-bg-color: transparent;
  --button-color: var(--color-grey-100);
  --button-border-color: var(--color-alpha-light-20);
  --button-hover-border-color: var(--color-alpha-light-5);
  --button-hover-bg-color: var(--color-grey-100);
  --button-hover-color: var(--color-grey-08);
  --button-icon-bg-color: var(--button-glass-bg);
}

.UIButtonBase_buttonIcon__MGMoh {
  position: absolute;
  z-index: var(--z-index-2);
  top: 50%;
  left: 50%;
  display: flex;
  width: 54%;
  height: auto;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
}

.UIButtonBase_buttonIcon__MGMoh svg {
    width: 100%;
    height: auto;
  }

/* stylelint-disable function-parentheses-space-inside */
/* stylelint-disable length-zero-no-unit */
.Shadow_shadowWrapper__FvkWr {
  position: relative;
}
.Shadow_shadowWrapper__FvkWr.Shadow_standalone__fAE5Q {
    position: absolute;
    z-index: var(--z-index-neg);
    inset: 0;
  }
.Shadow_shadowWrapper__FvkWr {

  --default-shadow-scale: 1;
  --shadow-bottom-height: calc(var(--shadow-scale, var(--default-shadow-scale))*250px);
  --shadow-right-height: calc(var(--shadow-scale, var(--default-shadow-scale))*210px);
  --shadow-bottom-width: calc(var(--shadow-scale, var(--default-shadow-scale))*195px);
  --shadow-right-width: calc(var(--shadow-scale, var(--default-shadow-scale))*0px);
  --shadow-bottom-left-offset: calc(var(--shadow-scale, var(--default-shadow-scale))*-1px);
  --shadow-bottom-top-offset: calc(30px + var(--shadow-radius, 0px)/3);
  --shadow-right-offset: calc(var(--shadow-scale, var(--default-shadow-scale))*55px - var(--shadow-radius, 0px)/2);
  --shadow-bottom-right-offset: calc(var(--shadow-right-width) - var(--shadow-scale, var(--default-shadow-scale))*-140px + var(--shadow-radius, 0px)/2);
}
.Shadow_shadow__JmEBL {
  position: absolute;
  z-index: var(--z-index-0);
  inset: 0;
  pointer-events: none;
}
.Shadow_bottom__VBXQX {
  position: absolute;
  top: calc(100% - var(--shadow-scale, var(--default-shadow-scale))*var(--shadow-bottom-top-offset));
  left: calc(var(--shadow-bottom-left-offset)*-1);
  display: flex;
  width: calc(100% + var(--shadow-bottom-right-offset) + var(--shadow-bottom-left-offset));
  height: var(--shadow-bottom-height);
}
.Shadow_right__vVUBG {
  position: absolute;
  top: 0;
  left: calc(100% - var(--shadow-right-offset));
  display: flex;
  width: var(--shadow-right-width);
  height: calc(100% - var(--shadow-scale, var(--default-shadow-scale))*var(--shadow-bottom-top-offset));
  flex-direction: column;
}
.Shadow_topRight__VIyei {
  height: min(100%, var(--shadow-right-height));
  flex-grow: 0;
}
.Shadow_middleRight__m2spU {
  position: relative;
  flex-grow: 1;
}
.Shadow_topRight__VIyei,
.Shadow_middleRight__m2spU {
  position: relative;
  overflow: hidden;
  width: var(--shadow-bottom-width);
}
.Shadow_middleRightImg__tXfSd {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.Shadow_bottomLeft__hh9nY,
.Shadow_bottomMiddle__xXJc1,
.Shadow_bottomRight__qnUMJ {
  position: relative;
  overflow: hidden;
  height: calc(var(--shadow-bottom-height));
}
.Shadow_bottomLeft__hh9nY {
  width: var(--shadow-bottom-width);
  flex-grow: 0;
}
.Shadow_bottomMiddle__xXJc1 {
  position: relative;
  flex-grow: 1;
}
.Shadow_bottomRight__qnUMJ {
  width: var(--shadow-bottom-width);
  flex-grow: 0;
}
.Shadow_shadowImage__pTZEN {
  height: 100%;
}

.BulletList_bulletList__MD6KD {
  list-style: disc outside none;
  list-style: initial;
}

.NumberList_numberList__8xLcf {
  list-style: disc outside none;
  list-style: initial;
}

.BulletListItem_listItemUl__UCQuC {
  display: list-item;
  margin-left: var(--spacer-24);
}

.NumberListItem_listItemOl__5J6o1 {
  display: list-item;
  margin-left: var(--spacer-24);
  list-style-type: decimal;
}

  .NumberListItem_listItemOl__5J6o1 .NumberListItem_listItemOl__5J6o1 {
    list-style-type: upper-alpha;
  }

  .NumberListItem_listItemOl__5J6o1 .NumberListItem_listItemOl__5J6o1 .NumberListItem_listItemOl__5J6o1 {
      list-style-type: upper-roman;
    }

.SplitText_word__LpXF6,
.SplitText_letter__QFEQ5 {
  display: inline-block;
  text-decoration: inherit;
}

.SplitText_emptySpace__S5Zcy {
  display: inline;
  visibility: hidden;
}

/* allows marks to inherit parent properties like perspective */

.SplitText_marks__qfTYL {
  display: contents;
}

.PortableText_portableText__iCL6x {
  position: relative;
}

  .PortableText_portableText__iCL6x br {
    display: none;
  }

  @media (min-width: 1240px) {

  .PortableText_portableText__iCL6x br {
      display: block
  }
    }

/* typography */

.PortableText_title1__NfBJS {
}

.PortableText_title2__uOViD {
}

.PortableText_title3__dwWoR {
}

.PortableText_title4___Ifwb {
}

.PortableText_title5__n09GN {
}

.PortableText_title55__lHLsY {
}

.PortableText_title6__eaZht {
}

.PortableText_ribbon__gP8RA {
}

.PortableText_formDisclaimer__FMtM_ {
}

.PortableText_body__IxLAD {
}

/* TODO: delete once v4 design system is fully implemented, not used in v4 */

.PortableText_eyebrowSmall__rUnL2 {
}

.PortableText_eyebrow__tGjcG {
}

.PortableText_em__R_wKh {
}

.PortableText_label__9Snwq {
}

.PortableText_strong__BoFEc {
}

.PortableText_link__0_w3U {
}

.PortableText_footnote__iCtmg {
}

.PortableText_list__uQ_AS {
  display: block;
}

.PortableText_list__uQ_AS li strong {
      color: var(--color-grey-80);
    }

.PortableText_bullet__JIdgF,
.PortableText_number__uL__e {
  list-style: disc outside none;
  list-style: initial;
}

/* stylelint-disable-next-line selector-class-pattern */

.PortableText_u__7KC1d {
  text-decoration: underline;
}

.DriftMark_driftMark__6KqVD {
  background-color: inherit;
  font: inherit;
  text-decoration: underline;
}

.FootnoteMark_superscript__Y9iQJ {
  border-bottom: 1px dotted;
  font-size: 0.75em;
}

.OpenConsentButton_openContentButton__JlqEW {
  background-color: inherit;
  font: inherit;
  text-decoration: underline;
}

@media (min-width: 768px) {.FaqItem_faqItem__HfaUX .FaqItem_answer__77Dqo {
      padding: 0
  }
    }

.FaqItem_question__IbZrz {
}

.FaqItem_questionIcon__XXQ9k {
  width: 1.6rem;
  height: 1.6rem;
}

.FaqItem_answer__77Dqo {
  color: var(--color-grey-60);
}

.FaqItem_portableText__4Lt0k {
}

@media (min-width: 768px) {

.FaqItem_portableText__4Lt0k {
    --subgrid-start: col 2;
    --subgrid-width: calc(var(--grid-number-of-columns) - 2)
}
  }

.FaqItem_portableText__4Lt0k p {
    color: var(--grey-brand-grey-60, #81859f);
  }

.FaqItem_portableText__4Lt0k .FaqItem_paragraph__Mu6qU {
    margin-bottom: var(--spacer-16);
    grid-column: start / end;
  }

@media (min-width: 768px) {

.FaqItem_portableText__4Lt0k .FaqItem_paragraph__Mu6qU {
      grid-column: start / span 9
  }
    }

@media (min-width: 1240px) {

.FaqItem_portableText__4Lt0k .FaqItem_paragraph__Mu6qU {
      grid-column: start / span 7
  }
    }

.FaqItem_portableText__4Lt0k .FaqItem_paragraph__Mu6qU:last-child {
      margin-bottom: 0;
    }

.FaqItem_portableText__4Lt0k > *:last-child {
      margin-bottom: 0;
      margin-bottom: initial;
    }

.Simple_canvas__EsDM8 {
  height: 100vh;
}

.Lists_listsContainer__csg_E {
  position: relative;
}

.Lists_content__ms0By {

  --subgrid-start: start;
  --subgrid-width: var(--grid-number-of-columns);
}

.Lists_eyebrow__oOfaX,
.Lists_titles__vtH9t,
.Lists_bodies__xAADc,
.Lists_buttons__Vd9iB {
  grid-column: start / end;
}

@media (min-width: 768px) {

.Lists_eyebrow__oOfaX,
.Lists_titles__vtH9t,
.Lists_bodies__xAADc,
.Lists_buttons__Vd9iB {
    grid-column: col 2 / span 12
}
  }

@media (min-width: 768px) {

.Lists_alignCenter__OExWb .Lists_eyebrow__oOfaX, .Lists_alignCenter__OExWb .Lists_titles__vtH9t, .Lists_alignCenter__OExWb .Lists_bodies__xAADc, .Lists_alignCenter__OExWb .Lists_buttons__Vd9iB {
      text-align: center
  }
    }

.Lists_eyebrow__oOfaX {
}

.Lists_bodies__xAADc {
  margin-top: var(--spacer-20);
  color: var(--color-alpha-light-50);
}

@media (min-width: 1240px) {

.Lists_bodies__xAADc {
    grid-column: col 2 / span 8
}
  }

@media (min-width: 768px) {

.Lists_alignCenter__OExWb .Lists_bodies__xAADc {
      grid-column: col 4 / span 8
  }
    }

.Lists_buttons__Vd9iB {
  margin-top: var(--spacer-32);
}

.Lists_lists__gLzb_ {
  row-gap: var(--spacer-48);

  --list-width: 4;
}

@media (min-width: 768px) {

.Lists_lists__gLzb_ {
    row-gap: var(--spacer-64);

    --list-width: 6
}
  }

.Lists_lists__gLzb_.Lists_with3Columns__eNMoJ {
    --list-item-margin: var(--spacer-16);
  }

@media (min-width: 960px) {

.Lists_lists__gLzb_.Lists_with3Columns__eNMoJ {
      --list-width: 4
  }
    }

.Lists_lists__gLzb_.Lists_with2Columns__utfQB {
    --list-item-margin: var(--spacer-16);
  }

@media (min-width: 768px) {

.Lists_lists__gLzb_.Lists_with2Columns__utfQB {
      --list-item-margin: var(--spacer-16);
      --list-width: 6
  }
    }

@media (min-width: 1240px) {

.Lists_lists__gLzb_.Lists_with2Columns__utfQB {
      --list-item-margin: var(--spacer-16);
      --list-width: 5
  }

      .Lists_lists__gLzb_.Lists_with2Columns__utfQB .Lists_list__oA01c {
        --list-start: 2;
      }

        .Lists_lists__gLzb_.Lists_with2Columns__utfQB .Lists_list__oA01c:nth-of-type(even) {
          --list-start: 8;
        }
    }

.Lists_list__oA01c {
  grid-column: var(--list-start, auto) / span var(--list-width);
}

@media (min-width: 768px) {

.Lists_list__oA01c {
    margin-bottom: var(--spacer-24)
}
  }

@media (min-width: 1240px) {

.Lists_list__oA01c {
    margin-bottom: var(--spacer-16)
}
  }

.Lists_listLabel__WkW3R {
  margin-bottom: var(--list-item-margin);
}

.Lists_listItem__0jgpU {
  display: flex;
  align-items: center;
  margin-bottom: var(--list-item-margin);
}

.Lists_listItem__0jgpU:last-of-type {
  margin-bottom: 0;
}

.Lists_listItemIconWrapper__djVIo {
  display: flex;
  height: 2.4rem;
  flex-shrink: 0;
  align-items: center;
  margin-right: var(--list-item-margin);
}

.Lists_listItemIcon__ghEjV {
  width: 1.9rem;
}

.Lists_listItemIcon__ghEjV path {
  fill: var(--color-accent);
}

.Lists_listItemText__X52xo {
  color: var(--color-grey-70);
}

.FloatingActionButton_dummyFullSection__4ruJU {
  position: absolute;
  top: var(--fab-top-position, 0);
  left: 0;
  width: 100%;
  height: calc(100% - var(--fab-top-position, 0));
  pointer-events: none;
}
.FloatingActionButton_floatingActionButtonContainer__jfExV {
  /*  if the FAB is the first item in a covering section (with top rounded corners), clear the default padding that's set for the rounded corners */
  --rounded-top-padding: 0;

  /* when fab is last module and next section is "below", override the default rounded corners spacing */
  --rounded-bottom-padding: var(--spacer-84);
  position: sticky;
  z-index: var(--z-index-10);

  --fab-sticky-bottom: var(--spacer-16);
  top: calc(100vh - var(--fab-height) - var(--fab-sticky-bottom));
  left: 0;
  display: flex;
  width: 100%;
  justify-content: center;
}
@media (min-width: 768px) {
.FloatingActionButton_floatingActionButtonContainer__jfExV {
    --fab-sticky-bottom: var(--spacer-24)
}
  }
@media (min-width: 1240px) {
.FloatingActionButton_floatingActionButtonContainer__jfExV {
    --fab-sticky-bottom: var(--spacer-32)
}
  }
/* Prevent FAB to move around if it's the last module of the page */
.FloatingActionButton_floatingActionButtonContainer__jfExV:last-child {
    position: relative;
    top: 0;
  }
.FloatingActionButton_floatingActionButton__rOmVT {
  /* accomodate for the scaling in hover state so border doesn't get cut off */
  padding-bottom: 0.1rem;
  border-radius: 0.8rem;
  background: var(--color-alpha-black-100);
}
@media (hover: hover) {
        .FloatingActionButton_floatingActionButton__rOmVT:hover .FloatingActionButton_hoverWrapper__SEZZZ::before {
          background-color: var(--color-alpha-light-5);
        }

      .FloatingActionButton_floatingActionButton__rOmVT:hover .FloatingActionButton_videoPreview__j_H_X {
        transform: scale(1.25);
      }
  }
.FloatingActionButton_floatingActionButton__rOmVT:active {
    transform: translateY(2px);
  }
.FloatingActionButton_floatingActionButton__rOmVT:active .FloatingActionButton_hoverWrapper__SEZZZ::before {
        background-color: var(--color-alpha-black-50);
      }
.FloatingActionButton_hoverWrapper__SEZZZ {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0.3rem var(--spacer-16) 0.3rem 0.3rem;

  /* prevents flashing before animating in on mobile */
  visibility: hidden;
}
.FloatingActionButton_hoverWrapper__SEZZZ::before {
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border: 1px solid var(--color-alpha-light-10);
    border-radius: 0.8rem;
    background: var(--color-alpha-black-90) radial-gradient(713.82% 279.6% at 10% -45.83%, rgb(125 125 204 / 15%) 0%, rgb(157 157 255 / 0%) 100%);
    box-shadow: 32px 29px 25.707px 0 rgb(0 0 0 / 35%);
    content: '';
    inset: 0;

    /* transform origin x is the size of the video preview + the padding of the parent */
    transform-origin: 6.8rem center;
    transition:
      var(--duration-300ms) transform var(--ease-basic-butter),
      var(--duration-300ms) background-color var(--ease-basic-butter);
  }
.FloatingActionButton_videoPreviewWrapper__RQFtQ {
  position: relative;
  z-index: var(--z-index-2);
  overflow: hidden;
  width: 6.5rem;
  height: 4rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  transform-origin: calc(100% + 2rem) center;
  transition: var(--duration-300ms) transform var(--ease-basic-butter);
}
.FloatingActionButton_videoPreview__j_H_X {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transform-origin: center top;
  transition: var(--duration-300ms) transform var(--ease-basic-butter);
}
.FloatingActionButton_icon__CNvAC {
  position: absolute;
  z-index: var(--z-index-2);
  top: 50%;
  left: 50%;
  height: 1.1rem;
  fill: var(--color-alpha-light-80);
  translate: -50% -50%;
}
.FloatingActionButton_label__UFKWX {
  z-index: var(--z-index-1);
  margin-left: var(--spacer-16);
}
.FloatingActionButton_labelInnerText__0rVpd {
  display: block;
  transition: var(--duration-300ms) transform var(--ease-basic-butter);
  white-space: nowrap;
}
.FloatingActionButton_modalPlaceholder__9Uy_S {
  position: fixed;
  z-index: calc(var(--z-index-modal) + 1);
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 5vh 5vw;
  inset: 0;
  place-items: center;
  pointer-events: none;
}
.FloatingActionButton_modalPlaceholder__9Uy_S .FloatingActionButton_modalCloseButton__LmJ9K {
    position: absolute;
    z-index: var(--z-index-2);
    top: var(--spacer-40);
    right: var(--spacer-40);
    display: block;
    width: var(--spacer-40);
    height: var(--spacer-40);
    box-shadow: var(--button-box-shadow);
    opacity: 0;
  }
.FloatingActionButton_modalBackground__j4XRo {
  position: absolute;
  background: var(--color-grey-00);
  inset: 0;
  opacity: 0;
}
.FloatingActionButton_modalVideoPlaceholder__4hh9N {
  width: 100%;
  height: 100%;
  align-self: center;
  aspect-ratio: var(--aspect-ratio);
}
.FloatingActionButton_modalVideoWrapper__2lZTM {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-alpha-light-10);
  border-radius: 1rem;
  opacity: 0;
}
.FloatingActionButton_modalVideo__yNdYN {
  width: var(--global-max-width);
  max-width: calc(100vw - var(--grid-margin-size));
  max-height: calc(100vh - var(--grid-margin-size));
}

/*  if the module's parent section has rounded corners, we need to add some padding to the module to accomodate for the border-radius. can be overwritten in module specific css files with the --rounded-padding variable. example: modules with full width/height images don't need extra padding, as the image extends to the full width/height of the module */
.ModuleWrapper_roundedBottomPadding__H1AgZ {
  padding-bottom: var(--rounded-bottom-padding, var(--default-rounded-bottom-padding));

  --default-rounded-bottom-padding: var(--spacer-64);
}
@media (min-width: 768px) {
.ModuleWrapper_roundedBottomPadding__H1AgZ {
    --default-rounded-bottom-padding: var(--spacer-96)
}
  }
@media (min-width: 1240px) {
.ModuleWrapper_roundedBottomPadding__H1AgZ {
    --default-rounded-bottom-padding: var(--spacer-124)
}
  }
.ModuleWrapper_roundedTopPadding__GI9fZ {
  padding-top: var(--rounded-top-padding, var(--default-rounded-top-padding));

  --default-rounded-top-padding: var(--spacer-64);
}
@media (min-width: 768px) {
.ModuleWrapper_roundedTopPadding__GI9fZ {
    --default-rounded-top-padding: var(--spacer-96)
}
  }
@media (min-width: 1240px) {
.ModuleWrapper_roundedTopPadding__GI9fZ {
    --default-rounded-top-padding: var(--spacer-124)
}
  }
/* default spacing for modules directly preceding (covered) an above section or directly following a below section(revealed) by the sections parallax. these values determine the final "end" state of the parallax (when the section has a translateY value of 0). accounts for rounded corners overlap(--section-overlap). */
/* default spacing values can be overwritten in module specific css files with --module-padding vars or by cms spacers. */
.ModuleWrapper_moduleWrapper__BCXKK {
  --parallax-extra-padding: 1;

  /* position relative to apply z-index */
  position: relative;

  /* default z-index to 1 (some module will override it if necessary
  eg: PotholeV4 */
  z-index: var(--module-z-index, var(--z-index-1));
}
/* --fab-margin-top is defined in PageSection css */
.ModuleWrapper_moduleWrapper__BCXKK.ModuleWrapper_precedesFab__yZBMC {
    margin-bottom: var(--fab-margin-top);
  }
.ModuleWrapper_moduleWrapper__BCXKK.ModuleWrapper_followsFab__bntqh {
    margin-top: calc((var(--fab-height, 0px) + var(--fab-margin-top))*-1);
  }
@media (min-width: 768px) {
.ModuleWrapper_moduleWrapper__BCXKK {
    /* add some extra space to accomodate for the parallax reveal that travels all the way to the top */
    --parallax-extra-padding: 1.3
}
  }
.ModuleWrapper_moduleWrapper__BCXKK.ModuleWrapper_isRevealedModule__7s_37 {
    --default-padding-top: var(--spacer-84);
    padding-top: calc(var(--module-padding-top, var(--default-padding-top)) + var(--section-overlap-top, 0));
  }
@media (min-width: 768px) {
.ModuleWrapper_moduleWrapper__BCXKK.ModuleWrapper_isRevealedModule__7s_37 {
      --default-padding-top: var(--spacer-96)
  }
    }
@media (min-width: 1240px) {
.ModuleWrapper_moduleWrapper__BCXKK.ModuleWrapper_isRevealedModule__7s_37 {
      --default-padding-top: var(--spacer-124)
  }
    }
.ModuleWrapper_moduleWrapper__BCXKK.ModuleWrapper_isCoveredModule__mCZr8 {
    --padding-bottom: var(--module-padding-bottom, calc(var(--default-padding-bottom)*var(--parallax-extra-padding)));

    /* the negative bottom margin will determine how much the module is covered by the next element in its final covered state. default value can be can be overwritten in module specific css files with the --covered-module-negative-bottom-margin variable. */
    --default-negative-bottom-margin: calc((var(--spacer-height-after, var(--padding-bottom)) + var(--section-overlap-bottom))/2);
    padding-bottom: calc(var(--padding-bottom) + var(--section-overlap-bottom, 0));
    margin-bottom: calc(var(--covered-module-negative-bottom-margin, var(--default-negative-bottom-margin))*-1);

    --default-padding-bottom: var(--spacer-84);
  }
@media (min-width: 768px) {
.ModuleWrapper_moduleWrapper__BCXKK.ModuleWrapper_isCoveredModule__mCZr8 {
      --default-negative-bottom-margin: calc(var(--spacer-height-after, var(--padding-bottom)) + var(--section-overlap-bottom));
      --default-padding-bottom: var(--spacer-124)
  }
    }
@media (min-width: 1240px) {
.ModuleWrapper_moduleWrapper__BCXKK.ModuleWrapper_isCoveredModule__mCZr8 {
      --default-padding-bottom: var(--spacer-160)
  }
    }
.ModuleWrapper_moduleWrapper__BCXKK.ModuleWrapper_isCoveredLastModuleInSectionWithFab__S8qi9 {
    --default-padding-bottom: 0;
  }

.Form_wrapper__TYNvk {
}

.Form_inner__RZlVF {
  padding: var(--spacer-40) var(--spacer-24);
  border: solid 1px var(--color-alpha-light-10);
  border-radius: var(--border-radius-5);
  margin: 0 var(--spacer-24);
  background: var(--color-alpha-light-5);
}

@media (min-width: 768px) {

.Form_inner__RZlVF {
    padding: var(--spacer-64);
    margin: 0
}
  }

@media (min-width: 1240px) {

.Form_inner__RZlVF {
    padding: var(--spacer-96)
}
  }

.Form_inner__RZlVF.Form_blurredBackground__CAbQt {
    -webkit-backdrop-filter: blur(var(--card-background-blur));
            backdrop-filter: blur(var(--card-background-blur));
    background: var(--color-alpha-dark-50);
  }

.Form_contentTextLockup__a6eHt {
  display: flex;
  flex-direction: column;
}

/* title4block applies a dash for all em's which is probably not correct but don't want to run through the whole validation right now, trying to get this one out */

.Form_contentTextLockup__a6eHt em::before {
    display: none;
  }

.Form_contentTextLockup__a6eHt p {
    margin-top: var(--spacer-16);
  }

.Form_form__jSQNa {
  grid-column: start / end;
}

.Form_row__T9ILQ {
  grid-column: start / end;
}

.Form_headingWrapper__p6fBv {
  grid-column: start / end;
}

.Form_submitRow__5BFyc {
  align-items: start;
  margin-top: var(--spacer-48);
  grid-column: span 12;
}

.Form_disclaimerButton__zldnM {
  grid-column: start / end;
  grid-row: 1;
}

@media (min-width: 768px) {

.Form_disclaimerButton__zldnM {
    margin-bottom: 0;
    grid-column: start / span 7
}
  }

.Form_disclaimerContent__1qThj {
  margin-bottom: var(--spacer-48);
  color: var(--color-alpha-light-60);
  grid-column: start / end;
  grid-row: 1;
}

@media (min-width: 768px) {

.Form_disclaimerContent__1qThj {
    margin-bottom: 0;
    grid-column: start / span 7
}
  }

.Form_disclaimerContent__1qThj.Form_requiresConsent__E_r_9 {
    grid-row: 2;
  }

@media (min-width: 1240px) {

.Form_disclaimerContent__1qThj.Form_requiresConsent__E_r_9 {
      padding-left: var(--spacer-64);
      grid-column: start / span 8
  }
    }

.Form_disclaimerContent__1qThj.Form_error__MhkOw {
    color: var(--color-coral);
  }

.Form_submitWrapper__gq8Jn {
  grid-column: start / end;
  grid-row: 3;
  text-align: right;
}

@media (min-width: 768px) {

.Form_submitWrapper__gq8Jn {
    grid-column: span 5 / end;
    grid-row: 1
}
  }

@media (min-width: 1921px) {

.Form_submitWrapper__gq8Jn {
    grid-column: span 3 / end
}
  }

.Form_submit__GzpXZ {
  width: 100%;
}

@media (min-width: 768px) {

.Form_submit__GzpXZ {
    width: auto
}
  }

.Form_submit__GzpXZ[disabled] {
    cursor: not-allowed;
  }

.Form_titleSpan__m_6TQ {
  display: block;
}

.Form_confirmationScreenContent__Ljq3Q {
  grid-column: start / end;

  --custom-icon-size: 4rem;
  --button-border-radius: 0.5rem;
}

/* Equal width buttons */

@media (min-width: 768px) {

.Form_confirmationScreenContent__Ljq3Q .Form_ctas__jllwL {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: repeat(var(--nb-buttons), minmax(auto, 1fr));
      justify-self: center
  }
    }

/* inputs - shared */
.FormInput_inputContainer__7Ally {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: var(--spacer-8);
  margin-top: var(--spacer-48);
}
.FormInput_inputContainer__7Ally.FormInput_required__TxqWo .FormInput_label__klPtq::after {
    position: absolute;
    top: -4px;
    right: -10px;
    color: var(--color-cobalt);
    content: '*';
  }
.FormInput_inputContainer__7Ally.FormInput_error__EyClP .FormInput_textInput__9WdxH,
    .FormInput_inputContainer__7Ally.FormInput_error__EyClP .FormInput_label__klPtq,
    .FormInput_inputContainer__7Ally.FormInput_error__EyClP .FormInput_label__klPtq::after,
    .FormInput_inputContainer__7Ally.FormInput_error__EyClP .FormInput_trigger__xDtsn,
    .FormInput_inputContainer__7Ally.FormInput_error__EyClP .FormInput_checkbox__Z0z5M,
    .FormInput_inputContainer__7Ally.FormInput_error__EyClP .FormInput_checkboxIcon__RmAPw {
      --border-color: var(--color-coral);
      color: var(--color-coral);
    }
.FormInput_inputContainer__7Ally.FormInput_error__EyClP .FormInput_caret__cwLo7 svg {
      fill: var(--color-coral);
    }
.FormInput_inputContainer__7Ally:hover .FormInput_mask__lOHDF::after {
    opacity: 1;
  }
.FormInput_error__EyClP .FormInput_textInput__9WdxH,
  .FormInput_error__EyClP .FormInput_label__klPtq,
  .FormInput_error__EyClP .FormInput_label__klPtq::after,
  .FormInput_error__EyClP .FormInput_trigger__xDtsn,
  .FormInput_error__EyClP .FormInput_checkbox__Z0z5M,
  .FormInput_error__EyClP .FormInput_checkboxIcon__RmAPw {
    --border-color: var(--color-coral);
    color: var(--color-coral);
    content: '';
  }
.FormInput_error__EyClP .FormInput_caret__cwLo7 svg {
    fill: var(--color-coral);
  }
.FormInput_span3__Dcswc,
.FormInput_span4__HRUoT,
.FormInput_span6__M0YGF,
.FormInput_span8__QXvsI,
.FormInput_span12__Zb3ZZ {
  grid-column: 1 / span 12;
}
@media (min-width: 768px) {
.FormInput_span3__Dcswc {
    grid-column: span 3
}
  }
@media (min-width: 768px) {
.FormInput_span4__HRUoT {
    grid-column: span 4
}
  }
@media (min-width: 768px) {
.FormInput_span6__M0YGF {
    grid-column: span 6
}
  }
@media (min-width: 768px) {
.FormInput_span8__QXvsI {
    grid-column: span 8
}
  }
@media (min-width: 768px) {
.FormInput_span12__Zb3ZZ {
    grid-column: 1 / span 12
}
  }
.FormInput_label__klPtq {
  position: absolute;
  bottom: 2.2rem;
  left: 0;
  color: var(--color-alpha-light-60);
  cursor: text;
  font-size: 1.6rem;
  pointer-events: none;
  transform: translateY(0);
  transition: all var(--duration-300ms) var(--ease-default);
  transition-delay: 10ms;
}
.FormInput_labelInner__7LbSs {
  position: relative;
  display: flex;
}
.FormInput_errorMessage__2evuZ {
  font-size: inherit;
  pointer-events: none;
}
.FormInput_errorMessage__2evuZ::before {
    padding: 0 var(--spacer-4);
    content: '—';
  }
.FormInput_textInput__9WdxH .FormInput_errorMessage__2evuZ {
    display: flex;
  }
/* gradient border underline */
.FormInput_mask__lOHDF {
  position: absolute;
  min-height: var(--spacer-48);
  inset: 0 0 var(--spacer-8);
  -webkit-mask-image: url('/form-input-mask-underline.svg');
          mask-image: url('/form-input-mask-underline.svg');
  pointer-events: none;

  --gradient-origin-x: 50%;
  --gradient-origin-y: 50%;
}
[data-selected='true'] .FormInput_mask__lOHDF:hover::after, .FormInput_error__EyClP .FormInput_mask__lOHDF:hover::after {
      opacity: 0;
    }
.FormInput_mask__lOHDF::after {
    position: absolute;
    z-index: -1;
    background: radial-gradient(circle, var(--color-grey-100), rgb(252 252 252 / 0%));
    content: '';
    inset: 0;
    opacity: 0;
    transform: translate3d(calc(var(--gradient-origin-x) - 50%), 0, 0);
    transition: opacity var(--duration-300ms) var(--ease-default);
  }
@media (hover: none) {
.FormInput_mask__lOHDF::after {
      display: none;
      content: none
  }
    }

/* checkboxGroup */

.CheckboxGroup_row__Se8mK {
  grid-column: start / end;
}

.CheckboxGroup_checkboxGroupContainer__3vwOt {
  display: block;
}

.CheckboxGroup_checkboxGroupContainer__3vwOt .CheckboxGroup_label__Gr1Xt {
    position: static;
    position: initial;
    display: inline-flex;
    margin-bottom: var(--spacer-8);
  }

.CheckboxGroup_label__Gr1Xt {
}

.CheckboxGroup_required__X6Vjh {
}

.CheckboxGroup_error__yZ7Hp {
}

.CheckboxGroup_error__yZ7Hp .CheckboxGroup_checkbox__uxLN_ {
    --border-color: var(--color-coral);
  }

.CheckboxGroup_error__yZ7Hp .CheckboxGroup_checkbox__uxLN_:hover .CheckboxGroup_checkboxMask__3DxDT::after {
        opacity: 0;
      }

.CheckboxGroup_errorMessage__uAsPz {
}

.CheckboxGroup_checkboxLabel__qIzf1 {
  grid-column: start / end;
}

.CheckboxGroup_checkboxLabel__qIzf1:not(:last-child) {
    margin-bottom: var(--spacer-12);
  }

@media (min-width: 768px) {

.CheckboxGroup_checkboxLabel__qIzf1 {
    grid-column: span 6
}
  }

@media (min-width: 1240px) {

.CheckboxGroup_checkboxLabel__qIzf1 {
    grid-column: span 3
}
  }

.CheckboxGroup_checkbox__uxLN_ {
  --border-color: transparent;
  position: relative;
  display: flex;
  height: var(--spacer-54);
  flex-direction: row;
  align-items: center;
  padding: var(--spacer-16);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-8);
  background: var(--color-alpha-light-5);
  color: var(--color-grey-100);
  cursor: pointer;
  transition: all var(--duration-300ms) var(--ease-default);
}

@media (min-width: 1240px) {

.CheckboxGroup_checkbox__uxLN_ {
    margin-bottom: 0;
}
  }

[data-selected='true'] .CheckboxGroup_checkbox__uxLN_ {
    background: var(--color-cobalt);
  }

[data-selected='true'] .CheckboxGroup_checkbox__uxLN_:hover .CheckboxGroup_checkboxMask__3DxDT::after {
        opacity: 0;
      }

[data-focused='true'] .CheckboxGroup_checkbox__uxLN_ {
    --border-color: var(--color-grey-100);
  }

[data-focused='true'] .CheckboxGroup_checkbox__uxLN_ .CheckboxGroup_checkboxMask__3DxDT::after {
      opacity: 0;
    }

.CheckboxGroup_checkbox__uxLN_:hover .CheckboxGroup_checkboxMask__3DxDT::after {
    opacity: 1;
  }

.CheckboxGroup_checkbox__uxLN_ {

  --gradient-origin-x: 50%;
  --gradient-origin-y: 50%;
}

.CheckboxGroup_checkboxMask__3DxDT {
  position: absolute;
  inset: -0.2rem;
  -webkit-mask-image: url('/form-input-mask.svg');
          mask-image: url('/form-input-mask.svg');
  pointer-events: none;
}

.CheckboxGroup_checkboxMask__3DxDT::after {
    position: absolute;
    z-index: -1;
    background: radial-gradient(circle, var(--color-grey-100), rgb(252 252 252 / 0%));
    content: '';
    inset: 0;
    opacity: 0;
    transform: translate3d(calc(var(--gradient-origin-x) - 50%), 0, 0);
    transition: opacity var(--duration-300ms) var(--ease-default);
  }

@media (hover: none) {

.CheckboxGroup_checkboxMask__3DxDT::after {
      display: none;
      content: none
  }
    }

.CheckboxGroup_checkboxIcon__8EH_F {
  --border-color: var(--color-grey-60);
  display: flex;
  width: var(--spacer-24);
  height: var(--spacer-24);
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-color: var(--border-color);
  border-radius: 4px;
  margin-right: var(--spacer-16);
}

.CheckboxGroup_checkboxIcon__8EH_F svg {
    display: block;
    width: 1.2rem;
    opacity: 0;
    transition: opacity var(--duration-300ms) var(--ease-default);
  }

[data-selected='true'] .CheckboxGroup_checkboxIcon__8EH_F {
    border-color: var(--color-grey-100);
    background: var(--color-grey-100);
  }

[data-selected='true'] .CheckboxGroup_checkboxIcon__8EH_F svg {
      fill: var(--color-cobalt);
      opacity: 1;
    }

.Switch_disclamerInputContainer__o0ih5 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
}

.Switch_switchContainer__3dS6N {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--spacer-16) 0;
  color: var(--color-grey-100);
  cursor: pointer;

  --border-color: transparent;
}

.Switch_switchContainer__3dS6N:not(.Switch_disclaimerLayout___4Qlm) {
    justify-content: space-between;
    padding: var(--spacer-16);
    border: 1px solid var(--border-color);
    border-radius: var(--spacer-8);
    background: var(--color-alpha-light-5);
    transition: border-color var(--duration-300ms) var(--ease-default);
  }

.Switch_switchContainer__3dS6N[data-focused='true'] {
    border-color: var(--color-grey-100);
  }

.Switch_switchContainer__3dS6N[data-focused='true']:not(.Switch_disclaimerLayout___4Qlm):hover .Switch_checkboxMask__B5b6l::after {
        opacity: 0;
      }

.Switch_switchContainer__3dS6N .Switch_checkboxMask__B5b6l {
    height: 5.9rem;
  }

.Switch_switchContainer__3dS6N:not(.Switch_disclaimerLayout___4Qlm).Switch_switchContainer__3dS6N:hover .Switch_checkboxMask__B5b6l::after {
    opacity: 1;
  }

.Switch_checkboxMask__B5b6l {
}

.Switch_errorMessage__SParl {
  display: inline-block;
}

.Switch_error__AgE7N {
}

.Switch_label__Dryct {
  bottom: auto;
  margin-left: var(--spacer-20);
}

.Switch_required__OiQRV {
}

.Switch_mask__k_0nJ {
}

.Switch_switch__OAhRb {
  position: relative;
  width: var(--spacer-48);
  height: var(--spacer-24);
  border-radius: var(--spacer-24);
  margin-left: auto;
  background: var(--color-grey-60);
  transition: all var(--duration-300ms) var(--ease-default);
}

[data-selected='true'] .Switch_switch__OAhRb {
    background: var(--color-cobalt);
  }

[data-selected='true'] .Switch_switch__OAhRb::after {
      transform: translateX(calc(var(--spacer-48) - var(--handle-height) - 4px));
    }

.Switch_switch__OAhRb::after {
    --handle-height: 20px;
    position: absolute;
    top: calc((var(--spacer-24) - var(--handle-height))/2);
    left: 2px;
    width: var(--handle-height);
    height: var(--handle-height);
    border-radius: var(--spacer-24);
    background: var(--color-grey-100);
    content: '';
    transform: translateX(0);
    transition: transform var(--duration-60ms) var(--ease-micro-bounce);
  }

.Switch_disclaimerLayout___4Qlm .Switch_switch__OAhRb {
    order: 1;
    margin-left: 0;
  }

.Switch_disclaimerLayout___4Qlm .Switch_label__Dryct {
    position: relative;
    order: 2;
    margin-left: var(--spacer-18);
  }

/* inputs - shared */
.TextInput_inputContainer__sXu_Q {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: var(--spacer-8);
  margin-top: var(--spacer-48);
}
.TextInput_inputContainer__sXu_Q.TextInput_required__6i_fM .TextInput_label__ZS8sD::after {
    position: absolute;
    top: -4px;
    right: -10px;
    color: var(--color-cobalt);
    content: '*';
  }
.TextInput_inputContainer__sXu_Q.TextInput_error__r7_eB .TextInput_textInput__d8dTK,
    .TextInput_inputContainer__sXu_Q.TextInput_error__r7_eB .TextInput_label__ZS8sD,
    .TextInput_inputContainer__sXu_Q.TextInput_error__r7_eB .TextInput_label__ZS8sD::after,
    .TextInput_inputContainer__sXu_Q.TextInput_error__r7_eB .TextInput_trigger__Fdj2U,
    .TextInput_inputContainer__sXu_Q.TextInput_error__r7_eB .TextInput_checkbox__CMjx1,
    .TextInput_inputContainer__sXu_Q.TextInput_error__r7_eB .TextInput_checkboxIcon__DF8uy {
      --border-color: var(--color-coral);
      color: var(--color-coral);
    }
.TextInput_inputContainer__sXu_Q.TextInput_error__r7_eB .TextInput_caret__GCdQJ svg {
      fill: var(--color-coral);
    }
.TextInput_inputContainer__sXu_Q:hover .TextInput_mask__TFnE6::after {
    opacity: 1;
  }
.TextInput_error__r7_eB .TextInput_textInput__d8dTK,
  .TextInput_error__r7_eB .TextInput_label__ZS8sD,
  .TextInput_error__r7_eB .TextInput_label__ZS8sD::after,
  .TextInput_error__r7_eB .TextInput_trigger__Fdj2U,
  .TextInput_error__r7_eB .TextInput_checkbox__CMjx1,
  .TextInput_error__r7_eB .TextInput_checkboxIcon__DF8uy {
    --border-color: var(--color-coral);
    color: var(--color-coral);
    content: '';
  }
.TextInput_error__r7_eB .TextInput_caret__GCdQJ svg {
    fill: var(--color-coral);
  }
.TextInput_span3__GCSRT,
.TextInput_span4__aI61q,
.TextInput_span6__DIFjI,
.TextInput_span8__t1d1g,
.TextInput_span12__oUIPn {
  grid-column: 1 / span 12;
}
@media (min-width: 768px) {
.TextInput_span3__GCSRT {
    grid-column: span 3
}
  }
@media (min-width: 768px) {
.TextInput_span4__aI61q {
    grid-column: span 4
}
  }
@media (min-width: 768px) {
.TextInput_span6__DIFjI {
    grid-column: span 6
}
  }
@media (min-width: 768px) {
.TextInput_span8__t1d1g {
    grid-column: span 8
}
  }
@media (min-width: 768px) {
.TextInput_span12__oUIPn {
    grid-column: 1 / span 12
}
  }
.TextInput_label__ZS8sD {
  position: absolute;
  bottom: 2.2rem;
  left: 0;
  color: var(--color-alpha-light-60);
  cursor: text;
  font-size: 1.6rem;
  pointer-events: none;
  transform: translateY(0);
  transition: all var(--duration-300ms) var(--ease-default);
  transition-delay: 10ms;
}
.TextInput_labelInner__Yatov {
  position: relative;
  display: flex;
}
.TextInput_errorMessage__e15gQ {
  font-size: inherit;
  pointer-events: none;
}
.TextInput_errorMessage__e15gQ::before {
    padding: 0 var(--spacer-4);
    content: '—';
  }
.TextInput_textInput__d8dTK .TextInput_errorMessage__e15gQ {
    display: flex;
  }
/* gradient border underline */
.TextInput_mask__TFnE6 {
  position: absolute;
  min-height: var(--spacer-48);
  inset: 0 0 var(--spacer-8);
  -webkit-mask-image: url('/form-input-mask-underline.svg');
          mask-image: url('/form-input-mask-underline.svg');
  pointer-events: none;

  --gradient-origin-x: 50%;
  --gradient-origin-y: 50%;
}
[data-selected='true'] .TextInput_mask__TFnE6:hover::after, .TextInput_error__r7_eB .TextInput_mask__TFnE6:hover::after {
      opacity: 0;
    }
.TextInput_mask__TFnE6::after {
    position: absolute;
    z-index: -1;
    background: radial-gradient(circle, var(--color-grey-100), rgb(252 252 252 / 0%));
    content: '';
    inset: 0;
    opacity: 0;
    transform: translate3d(calc(var(--gradient-origin-x) - 50%), 0, 0);
    transition: opacity var(--duration-300ms) var(--ease-default);
  }
@media (hover: none) {
.TextInput_mask__TFnE6::after {
      display: none;
      content: none
  }
    }
.TextInput_textInput__d8dTK {
  position: relative;
  height: var(--spacer-48);
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid var(--border-color);
  background: inherit;
  color: var(--color-grey-90);
  transition: border-color var(--duration-300ms) var(--ease-default);

  --border-color: var(--color-grey-20);
  --gradient-origin: 50%;
}
.TextInput_textInput__d8dTK:focus {
    /* Adding !important here because juggling with specificity is quite complicated with all the
		composition between inputs (the error state of the select dropdowns was overriding the focus
		state */
    /* stylelint-disable-next-line declaration-no-important */
    --border-color: var(--color-grey-100) !important;
  }
.TextInput_textInput__d8dTK:focus + .TextInput_label__ZS8sD {
      /* selectContainer has left-margin in resting state to accomodate caret */
      margin-left: 0;
      margin-left: initial;
      cursor: default;
      font-size: 1.2rem;
      transform: translateY(-3.2rem);
    }
/* hide required indicator */
.TextInput_textInput__d8dTK:focus + .TextInput_label__ZS8sD::after {
    content: '';
  }
/* override autofill styles */
.TextInput_textInput__d8dTK:-webkit-autofill {
    /* https://stackoverflow.com/a/64166144 */
    -webkit-background-clip: text;
            background-clip: text;
    background-color: transparent;

    /* https://stackoverflow.com/a/72205360 */
    -webkit-text-fill-color: var(--color-grey-90);
  }
.TextInput_textInput__d8dTK:autofill,
  .TextInput_textInput__d8dTK[data-com-onepassword-filled='dark'],
  .TextInput_textInput__d8dTK[data-com-onepassword-filled='light'] {
    /* https://stackoverflow.com/a/64166144 */
    -webkit-background-clip: text;
            background-clip: text;
    background-color: transparent;

    /* https://stackoverflow.com/a/72205360 */
    -webkit-text-fill-color: var(--color-grey-90);
  }
.TextInput_error__r7_eB {
}
.TextInput_required__6i_fM {
}
.TextInput_label__ZS8sD {
}
.TextInput_labelInner__Yatov {
}
.TextInput_errorMessage__e15gQ {
}
.TextInput_mask__TFnE6 {
}
/* truthy input styling */
.TextInput_textInput__d8dTK[type='text']:not([value='']) + .TextInput_label__ZS8sD, .TextInput_textInput__d8dTK[type='tel']:not([value='']) + .TextInput_label__ZS8sD, .TextInput_textInput__d8dTK[type='email']:not([value='']) + .TextInput_label__ZS8sD, .TextInput_error__r7_eB .TextInput_textInput__d8dTK + .TextInput_label__ZS8sD {
    /* selectContainer has left-margin in resting state to accomodate caret */
    margin-left: 0;
    margin-left: initial;
    cursor: default;
    font-size: 1.2rem;
    transform: translateY(-3.2rem);
  }
/* hide required indicator */
.TextInput_textInput__d8dTK[type='text']:not([value='']) + .TextInput_label__ZS8sD::after, .TextInput_textInput__d8dTK[type='tel']:not([value='']) + .TextInput_label__ZS8sD::after, .TextInput_textInput__d8dTK[type='email']:not([value='']) + .TextInput_label__ZS8sD::after, .TextInput_error__r7_eB .TextInput_textInput__d8dTK + .TextInput_label__ZS8sD::after {
    content: '';
  }

/* inputs - shared */
.SelectDropdown_inputContainer__SBVQm {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: var(--spacer-8);
  margin-top: var(--spacer-48);
}
.SelectDropdown_inputContainer__SBVQm.SelectDropdown_required__aKVvo .SelectDropdown_label__qx4US::after {
    position: absolute;
    top: -4px;
    right: -10px;
    color: var(--color-cobalt);
    content: '*';
  }
.SelectDropdown_inputContainer__SBVQm.SelectDropdown_error__6yWw1 .SelectDropdown_textInput__oa7VA,
    .SelectDropdown_inputContainer__SBVQm.SelectDropdown_error__6yWw1 .SelectDropdown_label__qx4US,
    .SelectDropdown_inputContainer__SBVQm.SelectDropdown_error__6yWw1 .SelectDropdown_label__qx4US::after,
    .SelectDropdown_inputContainer__SBVQm.SelectDropdown_error__6yWw1 .SelectDropdown_trigger__RnSyU,
    .SelectDropdown_inputContainer__SBVQm.SelectDropdown_error__6yWw1 .SelectDropdown_checkbox__JpaV5,
    .SelectDropdown_inputContainer__SBVQm.SelectDropdown_error__6yWw1 .SelectDropdown_checkboxIcon__aeFUO {
      --border-color: var(--color-coral);
      color: var(--color-coral);
    }
.SelectDropdown_inputContainer__SBVQm.SelectDropdown_error__6yWw1 .SelectDropdown_caret__klDBB svg {
      fill: var(--color-coral);
    }
.SelectDropdown_inputContainer__SBVQm:hover .SelectDropdown_mask__Ktm44::after {
    opacity: 1;
  }
.SelectDropdown_error__6yWw1 .SelectDropdown_textInput__oa7VA,
  .SelectDropdown_error__6yWw1 .SelectDropdown_label__qx4US,
  .SelectDropdown_error__6yWw1 .SelectDropdown_label__qx4US::after,
  .SelectDropdown_error__6yWw1 .SelectDropdown_trigger__RnSyU,
  .SelectDropdown_error__6yWw1 .SelectDropdown_checkbox__JpaV5,
  .SelectDropdown_error__6yWw1 .SelectDropdown_checkboxIcon__aeFUO {
    --border-color: var(--color-coral);
    color: var(--color-coral);
    content: '';
  }
.SelectDropdown_error__6yWw1 .SelectDropdown_caret__klDBB svg {
    fill: var(--color-coral);
  }
.SelectDropdown_span3__x52nA,
.SelectDropdown_span4__CaVJv,
.SelectDropdown_span6__s_Rrz,
.SelectDropdown_span8__4fI4U,
.SelectDropdown_span12__CoH1z {
  grid-column: 1 / span 12;
}
@media (min-width: 768px) {
.SelectDropdown_span3__x52nA {
    grid-column: span 3
}
  }
@media (min-width: 768px) {
.SelectDropdown_span4__CaVJv {
    grid-column: span 4
}
  }
@media (min-width: 768px) {
.SelectDropdown_span6__s_Rrz {
    grid-column: span 6
}
  }
@media (min-width: 768px) {
.SelectDropdown_span8__4fI4U {
    grid-column: span 8
}
  }
@media (min-width: 768px) {
.SelectDropdown_span12__CoH1z {
    grid-column: 1 / span 12
}
  }
.SelectDropdown_label__qx4US {
  position: absolute;
  bottom: 2.2rem;
  left: 0;
  color: var(--color-alpha-light-60);
  cursor: text;
  font-size: 1.6rem;
  pointer-events: none;
  transform: translateY(0);
  transition: all var(--duration-300ms) var(--ease-default);
  transition-delay: 10ms;
}
.SelectDropdown_labelInner__T8Hj3 {
  position: relative;
  display: flex;
}
.SelectDropdown_errorMessage__rJDRh {
  font-size: inherit;
  pointer-events: none;
}
.SelectDropdown_errorMessage__rJDRh::before {
    padding: 0 var(--spacer-4);
    content: '—';
  }
.SelectDropdown_textInput__oa7VA .SelectDropdown_errorMessage__rJDRh {
    display: flex;
  }
/* gradient border underline */
.SelectDropdown_mask__Ktm44 {
  position: absolute;
  min-height: var(--spacer-48);
  inset: 0 0 var(--spacer-8);
  -webkit-mask-image: url('/form-input-mask-underline.svg');
          mask-image: url('/form-input-mask-underline.svg');
  pointer-events: none;

  --gradient-origin-x: 50%;
  --gradient-origin-y: 50%;
}
[data-selected='true'] .SelectDropdown_mask__Ktm44:hover::after, .SelectDropdown_error__6yWw1 .SelectDropdown_mask__Ktm44:hover::after {
      opacity: 0;
    }
.SelectDropdown_mask__Ktm44::after {
    position: absolute;
    z-index: -1;
    background: radial-gradient(circle, var(--color-grey-100), rgb(252 252 252 / 0%));
    content: '';
    inset: 0;
    opacity: 0;
    transform: translate3d(calc(var(--gradient-origin-x) - 50%), 0, 0);
    transition: opacity var(--duration-300ms) var(--ease-default);
  }
@media (hover: none) {
.SelectDropdown_mask__Ktm44::after {
      display: none;
      content: none
  }
    }
.SelectDropdown_errorMessage__rJDRh {
}
.SelectDropdown_label__qx4US {
  margin-left: var(--spacer-20);
}
.SelectDropdown_required__aKVvo {
}
.SelectDropdown_mask__Ktm44 {
  pointer-events: none;
}
.SelectDropdown_textInput__oa7VA {
}
.SelectDropdown_trigger__RnSyU {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.SelectDropdown_trigger__RnSyU[aria-expanded='true'] .SelectDropdown_caret__klDBB {
    transform: rotate(180deg);
  }
.SelectDropdown_trigger__RnSyU:focus-visible {
    outline: 0;
  }
.SelectDropdown_caret__klDBB {
  width: 8.5px;
  height: 8.5px;
  margin-right: var(--spacer-12);
  transform: none;
  transition: transform var(--duration-60ms) var(--ease-micro-bounce);
}
.SelectDropdown_caret__klDBB svg {
    display: block;
    fill: var(--color-grey-100);
  }
.SelectDropdown_selectContainer__aO8nV .SelectDropdown_label__qx4US,
  .SelectDropdown_selectContainer__aO8nV .SelectDropdown_errorMessage__rJDRh {
    cursor: pointer;
  }
.SelectDropdown_selectContainer__aO8nV.SelectDropdown_selected__zAGgF .SelectDropdown_label__qx4US,
  .SelectDropdown_selectContainer__aO8nV.SelectDropdown_error__6yWw1 .SelectDropdown_label__qx4US {
    /* selectContainer has left-margin in resting state to accomodate caret */
    margin-left: 0;
    margin-left: initial;
    cursor: default;
    font-size: 1.2rem;
    transform: translateY(-3.2rem);
  }
/* hide required indicator */
.SelectDropdown_selectContainer__aO8nV.SelectDropdown_selected__zAGgF .SelectDropdown_label__qx4US::after, .SelectDropdown_selectContainer__aO8nV.SelectDropdown_error__6yWw1 .SelectDropdown_label__qx4US::after {
    content: '';
  }
.SelectDropdown_selectContainer__aO8nV.SelectDropdown_active__AkMtV .SelectDropdown_label__qx4US {
    opacity: 0;
  }
.SelectDropdown_optionsContainer__puOYL {
  /* nb this var provided by react-aria */
  width: var(--trigger-width);
  padding: var(--spacer-40) var(--spacer-24) var(--spacer-40) var(--spacer-24);
  border: 1px solid var(--alpha-light-5, rgb(157 157 255 / 5%));
  border-radius: 10px;
  -webkit-backdrop-filter: blur(125px);
          backdrop-filter: blur(125px);
  background: linear-gradient(344deg, rgb(255 255 255 / 0%) 59.57%, rgb(255 255 255 / 5%) 137.22%), rgb(157 157 255 / 5%);
  box-shadow: 25px 24px 64px rgb(0 0 0 / 80%);
  transform-origin: 50% 20%;

  --animation-duration: var(--duration-600ms);
}
.SelectDropdown_optionsContainer__puOYL[data-entering] {
    animation: SelectDropdown_expand__BDCif var(--animation-duration);
  }
.SelectDropdown_optionsContainer__puOYL[data-entering] .SelectDropdown_optionsHeader__ZEZL8,
    .SelectDropdown_optionsContainer__puOYL[data-entering] .SelectDropdown_options__ol5OH {
      animation: SelectDropdown_fade-in__O_z6y var(--animation-duration);
    }
.SelectDropdown_optionsContainer__puOYL[data-exiting] {
    animation: SelectDropdown_expand__BDCif var(--animation-duration) reverse var(--ease-basic-butter);
  }
.SelectDropdown_optionsContainer__puOYL[data-exiting] .SelectDropdown_optionsHeader__ZEZL8,
    .SelectDropdown_optionsContainer__puOYL[data-exiting] .SelectDropdown_options__ol5OH {
      animation: SelectDropdown_fade-in__O_z6y var(--animation-duration) reverse var(--ease-basic-butter);
    }
@keyframes SelectDropdown_expand__BDCif {
    0% {
      opacity: 0;
      transform: scale(1, 1);
    }

    10% {
      opacity: 0;

      /* nb if scale is applied at the start of the animation, it messes up the popover positioning */
      transform: scale(0, 0);
    }

    50% {
      transform: scale(1, 0);
    }

    100% {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
@keyframes SelectDropdown_fade-in__O_z6y {
    0% {
      opacity: 0;
      transform: translateY(-8px);
    }

    /* prevents text from revealing before parent scaling is complete */
    90% {
      opacity: 0;
      transform: translateY(-8px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
.SelectDropdown_optionsHeader__ZEZL8 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: var(--spacer-28);
  margin-left: var(--spacer-24);
}
.SelectDropdown_optionsHeader__ZEZL8 .SelectDropdown_caret__klDBB {
    transform: rotate(180deg);
  }
.SelectDropdown_optionsTitle___kUNJ {
  color: var(--color-grey-100);
}
.SelectDropdown_options__ol5OH {
  width: 100%;
  cursor: pointer;
}
.SelectDropdown_selectedValue__WF06e {
  color: var(--color-grey-90);
}
.SelectDropdown_optionItem__P6VP9 {
  position: relative;
  overflow: hidden;
  padding: var(--spacer-16) var(--spacer-24);
  border-radius: 0.8rem;
  color: var(--color-grey-90);

  --gradient-origin: 50%;
}
.SelectDropdown_optionItem__P6VP9:focus .SelectDropdown_optionItemGradient__mYTk7 {
    background: radial-gradient(circle, rgb(157 157 255 / 10%), rgb(157 157 255 / 0%));
    opacity: 1;
    transform: translate3d(calc(var(--gradient-origin) - 50%), 0, 0);
  }
/*  this style is intentionally nested under `optionItem`.
      when an item's selected, react-aria lifts the entire node into `<SelectValue />`.
      which would otherwise include the gradient background.
  */
.SelectDropdown_optionItem__P6VP9 .SelectDropdown_optionItemGradient__mYTk7 {
    position: absolute;
    width: 100%;
    height: 100%;
    background: none;
    inset: 0;
    opacity: 0;
    transition: opacity var(--duration-300ms) var(--ease-default);
  }
.SelectDropdown_optionItem__P6VP9 .SelectDropdown_optionItemGradient__mYTk7:hover {
      background: radial-gradient(circle, rgb(157 157 255 / 10%), rgb(157 157 255 / 0%));
      opacity: 1;
      transform: translate3d(calc(var(--gradient-origin) - 50%), 0, 0);
    }
.SelectDropdown_optionItem__P6VP9.SelectDropdown_unset__Pxzna {
    font-style: italic;
    opacity: 0.8;
  }

.SubmitButton_wrapper__OMN5i {
  position: relative;
}

.SubmitButton_button__sogV_ {
  overflow: hidden;
}

.SubmitButtonAnimation_animationWrapper__jchfz {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: var(--z-index-1);
  width: 100%;
  height: 100%;

  --icon-line-width: 0.25rem;
  --icon-color: var(--color-grey-100);
  --icon-padding: 0.9rem;
}

.SubmitButtonAnimation_background__VJYM2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  border-radius: 50%;
  aspect-ratio: 1;
  background-color: var(--color-cobalt-dark);
  transform: translate(-50%, -50%) scale(0);
}

.SubmitButtonAnimation_dot__vO6gD {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  aspect-ratio: 1;
  opacity: 0;
  transform: translate(-50%, -50%) rotate(-63deg);
  translate: unset;
}

.SubmitButtonAnimation_dot__vO6gD::after {
    position: absolute;
    top: var(--icon-padding);
    left: 50%;
    display: block;
    width: var(--icon-line-width);
    border-radius: 50%;
    aspect-ratio: 1;
    background-color: var(--icon-color);
    content: '';
    translate: -50% 0;
  }

.SubmitButtonAnimation_tick__Q06Zy,
.SubmitButtonAnimation_cross__v3LrM {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  aspect-ratio: 1;
}

.SubmitButtonAnimation_line__41zDk {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: var(--icon-line-width);
  height: var(--icon-line-width);
  border-radius: var(--icon-line-width);
  background-color: var(--icon-color);
  opacity: 0;
}

.SubmitButtonAnimation_cross1__3RuKB,
.SubmitButtonAnimation_cross2__U1p6f,
.SubmitButtonAnimation_tickShort__FhPZR,
.SubmitButtonAnimation_tickLong__NI2f2 {
}

.SubmitButtonAnimation_cross1__3RuKB {
  transform: translate(-50%, -50%) rotate(45deg);
}

.SubmitButtonAnimation_cross2__U1p6f {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.SubmitButtonAnimation_tickShort__FhPZR {
  transform: translate(-1.44rem, -0.64rem) rotate(45deg);
  transform-origin: right;
}

.SubmitButtonAnimation_tickShort__FhPZR:active {
    width: 0.8rem;
    transform: translate(-0.91rem, 0.45rem) rotate(45deg);
  }

.SubmitButtonAnimation_tickLong__NI2f2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-0.26rem, 0.43rem) rotate(-45deg);
  transform-origin: left;
}

.SubmitButtonAnimation_tickLong__NI2f2:active {
    width: 1.3rem;
  }

.SubmitButtonAnimation_circle__pFM96 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% - var(--icon-padding)*2);
  border: solid var(--icon-line-width) var(--color-alpha-light-50);
  border-radius: 50%;
  aspect-ratio: 1;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.7);
}

.Hero_hero__ZH8Mm {
  --covered-module-negative-bottom-margin: var(--parallax-start-y);
  position: relative;
  width: 100%;
  padding-bottom: 0;
  background-color: var(--color-grey-00);
}

.Hero_container__uGqO8 {
  position: relative;
  z-index: var(--z-index-1);

  /* accomodates for bottom browser bar in ios safari */
  min-height: calc(100svh + var(--spacer-28));
  align-content: end;
  padding-top: var(--spacer-96);
  padding-bottom: var(--default-rounded-bottom-padding);
  row-gap: 5.6rem;
}

@media (min-width: 768px) {

.Hero_container__uGqO8 {
    min-height: calc(100vh + var(--spacer-28));
    padding-top: var(--spacer-32)
}
  }

@media (min-width: 1921px) {

.Hero_container__uGqO8 {
    padding-bottom: calc(var(--default-rounded-bottom-padding)*var(--xl-scale-value))
}
  }

.Hero_container__uGqO8.Hero_isCoveredAndBottom__5VOjF {
    padding-bottom: var(--spacer-64);
  }

@media (min-width: 768px) {

.Hero_container__uGqO8.Hero_isCoveredAndBottom__5VOjF {
      padding-bottom: var(--spacer-96)
  }
    }

@media (min-width: 1240px) {

.Hero_container__uGqO8.Hero_isCoveredAndBottom__5VOjF {
      padding-bottom: var(--spacer-124)
  }
    }

.Hero_container__uGqO8.Hero_organicHeight__GspGw {
    min-height: 0;
    min-height: initial;
    padding: calc(var(--spacer-64) + var(--nav-height)) 0 var(--spacer-64);
  }

@media (min-width: 768px) {

.Hero_container__uGqO8.Hero_organicHeight__GspGw {
      padding: calc(var(--spacer-96) + var(--nav-height)) 0 var(--spacer-124)
  }
    }

@media (min-width: 1240px) {

.Hero_left__GDJp9 .Hero_content___5zob {
      /* negatively set the hero text so that the space between it and the left side of the viewport is 124px */
      left: calc((var(--grid-computed-margin-width) - var(--spacer-124))*-1)
  }
    }

@media (min-width: 1921px) {

.Hero_left__GDJp9 .Hero_content___5zob {
      /* negatively set the hero text so that the space between it and the left side of the viewport is 124px * 1.145 */
      left: calc((var(--grid-computed-margin-width) - (var(--spacer-124)*1.145))*-1)
  }
    }

.Hero_middle__hZKBq .Hero_container__uGqO8 {
    align-content: center;
  }

.Hero_top__qexjo .Hero_container__uGqO8 {
    align-content: start;
  }

.Hero_bottom__Zgj6J .Hero_container__uGqO8 {
    align-content: flex-end;
  }

.Hero_hero__ZH8Mm .Hero_backgroundWrapper__s1VpK {
  position: absolute;
  z-index: var(--z-index-0);
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;

  --z-index-gradient-overlay: var(--z-index-3);
}

.Hero_hero__ZH8Mm .Hero_backgroundWrapper__s1VpK .Hero_backgroundImageContainer__LCVoC {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -o-object-fit: cover;
       object-fit: cover;
    transition: var(--duration-600ms) opacity var(--ease-basic-butter);
    will-change: transform;
  }

.Hero_hero__ZH8Mm .Hero_backgroundWrapper__s1VpK .Hero_backgroundImageContainer__LCVoC.Hero_isHidden__ATP0F {
      opacity: 0;
    }

.Hero_hero__ZH8Mm .Hero_backgroundWrapper__s1VpK .Hero_bottomGlow__afQOe {
    position: absolute;
    z-index: var(--z-index-neg);
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20rem;
    transform: scaleX(1.5) rotate(-10deg);
  }

.Hero_hero__ZH8Mm .Hero_backgroundWrapper__s1VpK video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }

.Hero_hasFloatingVideoPreview__HjitO .Hero_content___5zob {
    grid-row: 2;
  }

@media (min-width: 768px) {

.Hero_hasFloatingVideoPreview__HjitO .Hero_content___5zob {
      grid-row: unset
  }
    }

.Hero_hasFloatingVideoPreview__HjitO .Hero_floatingVideoPreview__THbvd {
    margin-top: 0;
    grid-column: start/end;
    grid-row: 3;

    --preview-video-aspect-ratio: 1.7;
  }

@media (min-width: 768px) {

.Hero_hasFloatingVideoPreview__HjitO .Hero_floatingVideoPreview__THbvd {
      margin-top: 0;
      grid-column: unset
  }
    }

.CircleLoader_circleLoader__gVbnN {
  position: relative;
  width: 7.3rem;
  height: 7.3rem;
}

  .CircleLoader_circleLoader__gVbnN .CircleLoader_progressCircle__q1E6g {
    width: 100%;
    height: 100%;
  }

  .CircleLoader_circleLoader__gVbnN .CircleLoader_progressBar____9A_ {
    stroke-dasharray: 180;
    stroke-dashoffset: calc((1 - var(--progress, 0))*180);
    transform: rotate(-90deg);
    transform-origin: 50%;
  }

  .CircleLoader_circleLoader__gVbnN .CircleLoader_progressValue__b9I95 {
    position: absolute;
    top: 50%;
    left: 50%;
    color: var(--color-grey-60);
    font-family: var(--font-tertiary);
    font-size: 1.3rem;
    line-height: 1.42;
    transform: translate3d(-50%, -50%, 0);
  }

  .CircleLoader_circleLoader__gVbnN .CircleLoader_progressValue__b9I95 strong {
      color: var(--color-grey-90);
      font-size: 1.6rem;
    }

.HeroCard_heroCard__Lfbwx {
  overflow: hidden;
  width: var(--hero-card-width);
  border-radius: var(--border-radius-10);

  /* custom gradient to fake blur */
  background: linear-gradient(90deg, #1f1e1b 0%, #1a1a19 32.48%, #1f1f1b 51.59%, #25261e 99.98%);
  will-change: transform;
}

  /* black layer, mobile only */
  .HeroCard_heroCard__Lfbwx::before {
    position: absolute;
    border: 0.15rem solid var(--color-alpha-light-10);
    background-color: var(--color-grey-00);
    content: '';
    inset: 0;
    opacity: calc(1 - var(--card-content-opacity, 1));
  }
  @media (min-width: 768px) {.HeroCard_heroCard__Lfbwx {
    border: 0.15rem solid var(--color-alpha-light-10);
    -webkit-backdrop-filter: blur(30px);
            backdrop-filter: blur(30px);
    background: var(--color-alpha-dark-40)
}

    .HeroCard_heroCard__Lfbwx::before {
      display: none;
      opacity: 0;
    }
  }
  .HeroCard_heroCard__Lfbwx .HeroCard_cardMediaWrapper__c1qhj {
    position: relative;
    opacity: var(--card-content-opacity, 1);
  }
  /* Vapor-like fake checkbox */
  .HeroCard_heroCard__Lfbwx .HeroCard_cardMediaWrapper__c1qhj::after {
      position: absolute;
      z-index: var(--z-index-2);
      top: 1.05rem;
      left: 0.84rem;
      display: block;
      width: 1.68rem;
      height: 1.68rem;
      border-radius: 0.4rem;
      background-color: rgb(255 255 255 / 15%);
      content: '';
    }
  .HeroCard_heroCard__Lfbwx .HeroCard_cardMediaWrapper__c1qhj .HeroCard_loader__D56bX {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  .HeroCard_heroCard__Lfbwx .HeroCard_mediaInnerWrapper__LvPO3 {
    width: 100%;

    --image-aspect-ratio: 380/213;
  }
  .HeroCard_shouldAnimate__tPfKo .HeroCard_heroCard__Lfbwx .HeroCard_mediaInnerWrapper__LvPO3 {
      clip-path: circle(0%);
    }
  .HeroCard_heroCard__Lfbwx .HeroCard_mediaInnerWrapperInner__ogGRk {
    aspect-ratio: var(--image-aspect-ratio);
  }
  /* Vapor-like: not using our variables since it's mimicking the app */
  .HeroCard_heroCard__Lfbwx .HeroCard_chin__lO5ER {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0.8rem;
    font-family: var(--font-tertiary);
    font-weight: 400;
    gap: 0.3rem;
    opacity: var(--card-content-opacity, 1);
  }
  @media (min-width: 768px) {
  .HeroCard_heroCard__Lfbwx .HeroCard_chin__lO5ER {
      padding: 1.3rem;
      gap: 0.4rem
  }
    }
  .HeroCard_heroCard__Lfbwx .HeroCard_chin__lO5ER .HeroCard_lowerChin__w8Q6R {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  .HeroCard_heroCard__Lfbwx .HeroCard_chin__lO5ER .HeroCard_title__6L9pB {
      font-size: 0.9rem;
      font-weight: 500;
      line-height: 1.42;
    }
  @media (min-width: 768px) {
  .HeroCard_heroCard__Lfbwx .HeroCard_chin__lO5ER .HeroCard_title__6L9pB {
        font-size: 1.4rem
    }
      }
  .HeroCard_heroCard__Lfbwx .HeroCard_chin__lO5ER .HeroCard_author__zSpBZ {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
  .HeroCard_heroCard__Lfbwx .HeroCard_chin__lO5ER .HeroCard_author__zSpBZ::after {
        display: inline-block;
        width: 0.3rem;
        height: 0.3rem;
        border-radius: 50%;
        margin: 0 0.8rem;
        background-color: var(--color-grey-70);
        content: '';
      }
  .HeroCard_heroCard__Lfbwx .HeroCard_chin__lO5ER .HeroCard_subtitle__Xj_m_ {
      color: var(--color-alpha-light-50);
      font-size: 0.8rem;
      line-height: 1.42;
    }
  @media (min-width: 768px) {
  .HeroCard_heroCard__Lfbwx .HeroCard_chin__lO5ER .HeroCard_subtitle__Xj_m_ {
        font-size: 1.3rem
    }
      }
  .HeroCard_heroCard__Lfbwx .HeroCard_chin__lO5ER .HeroCard_more__rKyp0 {
      width: 1.1rem;
      height: 1.1rem;
    }
  @media (min-width: 768px) {
  .HeroCard_heroCard__Lfbwx .HeroCard_chin__lO5ER .HeroCard_more__rKyp0 {
        width: 1.7rem;
        height: 1.7rem
    }
      }

.HeroCardsTransition_heroCardsTransition__Ylv5O {
  position: relative;
  z-index: var(z-index-2);
  display: block;
  height: 150vh;
  background-color: transparent;

  --vertical-factor: 1;
  --hero-card-width: 25rem;
  --hero-card-gutter: var(--spacer-16);
  --hero-card-aspect-ratio: 1.33333;
  --hero-card-height: calc(var(--hero-card-width)/var(--hero-card-aspect-ratio));
  --normalized-parallax-progress: calc(var(--parallax-progress)*0.15);

  /* first cards column left offset from container */
  --first-column-x: calc((var(--hero-card-width) + var(--hero-card-gutter))*-1 - (100vw - var(--hero-card-width))/-2);
}

  @media (min-width: 768px) {.HeroCardsTransition_heroCardsTransition__Ylv5O {
    --first-column-x: -16.2rem;
    --hero-card-width: 37.8rem;
    --hero-card-gutter: var(--spacer-24)
}
  }

  @media (min-width: 1240px) {.HeroCardsTransition_heroCardsTransition__Ylv5O {
    --first-column-x: -20.9rem;
    --hero-card-width: 37.8rem;
    --normalized-parallax-progress: var(--parallax-progress) * 0.4
}
  }

  @media (min-width: 1921px) {.HeroCardsTransition_heroCardsTransition__Ylv5O {
    --first-column-x: -40.9rem;
    --hero-card-width: 50rem
}
  }

/* Background media */

.HeroCardsTransition_backgroundWrapper__Dhgg6 {
  position: absolute;
  z-index: var(--z-index-0);
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 110vh;
  transform: translate3d(0, calc(var(--parallax-progress)*25vh), 0);

  --z-index-gradient-overlay: var(--z-index-3);
}

.HeroCardsTransition_backgroundWrapper__Dhgg6 .HeroCardsTransition_backgroundImageContainer__AcV1l {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    will-change: transform;
  }

.HeroCardsTransition_backgroundWrapper__Dhgg6 .HeroCardsTransition_backgroundImageContainer__AcV1l::after {
      position: absolute;
      background: linear-gradient(0deg, rgb(0 0 0 / 20%) 0%, rgb(0 0 0 / 20%) 100%), linear-gradient(19deg, rgb(0 0 0 / 50%) 21.85%, rgb(0 0 0 / 0%) 73.55%);
      content: '';
      inset: 0;
      pointer-events: none;
    }

/* Text content */

.HeroCardsTransition_contentWrapper__AV0Qm {
  position: relative;
  display: flex;
  max-width: 144rem;
  flex-direction: column;
  padding-top: 22.5rem;
  margin: 0 auto;
}

@media (min-width: 768px) {

.HeroCardsTransition_contentWrapper__AV0Qm {
    padding-top: 32rem
}
  }

.HeroCardsTransition_contentWrapper__AV0Qm .HeroCardsTransition_content__EMoWq {
    width: 100%;
    align-items: center;
    align-self: var(--lockup-justify);
    padding: 0 var(--spacer-24);
    opacity: 0;
  }

@media (min-width: 768px) {

.HeroCardsTransition_contentWrapper__AV0Qm .HeroCardsTransition_content__EMoWq {
      width: calc(var(--grid-computed-column-width)*12 + var(--grid-gutter-size)*11);
      padding-left: var(--spacer-40)
  }
    }

.HeroCardsTransition_cardsWrapper__fG0h4 {
  /* position relative instead of absolute so it's placed at the baseline of the title block, so that every value used is based on that */
  position: relative;
  z-index: var(--z-index-1);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  /* pointer-events: none; */
}

.HeroCardsTransition_card__8m_DB {
  position: absolute;
  overflow: hidden;
  transform: translate3d(0, calc(10rem*(1 - var(--card-entry-progress, 0)) - var(--normalized-parallax-progress, 0)*100rem*var(--cards-column-translate-ratio)), 0);
  will-change: transform;

  --card-content-opacity: calc(var(--card-entry-progress, 0)*var(--card-scroll-progress, 1)*var(--card-max-opacity, 1));
  --base-top: 10%;
  --base-left: 0%;
}

@media (min-width: 768px) {

.HeroCardsTransition_card__8m_DB {
    transform: translate3d(0, calc(30rem*(1 - var(--card-entry-progress, 0)) - var(--normalized-parallax-progress, 0)*100rem*var(--cards-column-translate-ratio)), 0)
}
  }

/* Column 1 */

.HeroCardsTransition_card__8m_DB:nth-child(1) {
  --card-max-opacity: 0.55;
  --image-wall-increment: 0;
  --cards-column-translate-ratio: 0.2;
  top: calc(var(--base-top) + 11rem*var(--vertical-factor) + (var(--hero-card-height) + var(--hero-card-gutter))*0);
  left: calc(var(--base-left) + var(--first-column-x) + (var(--hero-card-width) + var(--hero-card-gutter))*0);
}

@media (min-width: 768px) {

.HeroCardsTransition_card__8m_DB:nth-child(1) {
    top: calc(var(--base-top) + -32rem*var(--vertical-factor) + (var(--hero-card-height) + var(--hero-card-gutter))*0)
}
  }

.HeroCardsTransition_card__8m_DB:nth-child(2) {
  --card-max-opacity: 0.55;
  --image-wall-increment: 1;
  --cards-column-translate-ratio: 0.2;
  top: calc(var(--base-top) + 11rem*var(--vertical-factor) + (var(--hero-card-height) + var(--hero-card-gutter))*1);
  left: calc(var(--base-left) + var(--first-column-x) + (var(--hero-card-width) + var(--hero-card-gutter))*0);
}

@media (min-width: 768px) {

.HeroCardsTransition_card__8m_DB:nth-child(2) {
    top: calc(var(--base-top) + -32rem*var(--vertical-factor) + (var(--hero-card-height) + var(--hero-card-gutter))*1)
}
  }

/* Column 2 */

.HeroCardsTransition_card__8m_DB:nth-child(3) {
  --card-max-opacity: 0.85;
  --image-wall-increment: 2;
  --cards-column-translate-ratio: 0.85;
  top: calc(var(--base-top) + 5.1rem*var(--vertical-factor) + (var(--hero-card-height) + var(--hero-card-gutter))*0);
  left: calc(var(--base-left) + var(--first-column-x) + (var(--hero-card-width) + var(--hero-card-gutter))*1);
}

@media (min-width: 768px) {

.HeroCardsTransition_card__8m_DB:nth-child(3) {
    top: calc(var(--base-top) + -32rem*var(--vertical-factor) + (var(--hero-card-height) + var(--hero-card-gutter))*0)
}
  }

.HeroCardsTransition_card__8m_DB:nth-child(4) {
  --card-max-opacity: 0.85;
  --image-wall-increment: 2;
  --cards-column-translate-ratio: 0.85;
  top: calc(var(--base-top) + 5.1rem*var(--vertical-factor) + (var(--hero-card-height) + var(--hero-card-gutter))*1);
  left: calc(var(--base-left) + var(--first-column-x) + (var(--hero-card-width) + var(--hero-card-gutter))*1);
}

@media (min-width: 768px) {

.HeroCardsTransition_card__8m_DB:nth-child(4) {
    top: calc(var(--base-top) + -32rem*var(--vertical-factor) + (var(--hero-card-height) + var(--hero-card-gutter))*1)
}
  }

/* Column 3  */

.HeroCardsTransition_card__8m_DB:nth-child(5) {
  --card-max-opacity: 1;
  --image-wall-increment: 0;
  --cards-column-translate-ratio: 0.4;
  top: calc(var(--base-top) + 7.9rem*var(--vertical-factor) + (var(--hero-card-height) + var(--hero-card-gutter))*0);
  left: calc(var(--base-left) + var(--first-column-x) + (var(--hero-card-width) + var(--hero-card-gutter))*2);
}

@media (min-width: 768px) {

.HeroCardsTransition_card__8m_DB:nth-child(5) {
    top: calc(var(--base-top) + -32rem*var(--vertical-factor) + (var(--hero-card-height) + var(--hero-card-gutter))*0)
}
  }

.HeroCardsTransition_card__8m_DB:nth-child(6) {
  --card-max-opacity: 1;
  --image-wall-increment: 1;
  --cards-column-translate-ratio: 0.4;
  top: calc(var(--base-top) + 7.9rem*var(--vertical-factor) + (var(--hero-card-height) + var(--hero-card-gutter))*1);
  left: calc(var(--base-left) + var(--first-column-x) + (var(--hero-card-width) + var(--hero-card-gutter))*2);
}

@media (min-width: 768px) {

.HeroCardsTransition_card__8m_DB:nth-child(6) {
    top: calc(var(--base-top) + -32rem*var(--vertical-factor) + (var(--hero-card-height) + var(--hero-card-gutter))*1)
}
  }

/* Column 4 */

.HeroCardsTransition_card__8m_DB:nth-child(7),
.HeroCardsTransition_card__8m_DB:nth-child(8),
.HeroCardsTransition_card__8m_DB:nth-child(9) {
  display: none;
}

/* Column 5 */

.HeroCardsTransition_card__8m_DB:nth-child(10),
.HeroCardsTransition_card__8m_DB:nth-child(11),
.HeroCardsTransition_card__8m_DB:nth-child(12) {
  display: none;
}

/* TABLET/DESKTOP LAYOUT */

/* Column 1 */

@media (min-width: 768px) {
  .HeroCardsTransition_card__8m_DB:nth-child(1) {
    --card-max-opacity: 0.55;
    --image-wall-increment: 0;
    --cards-column-translate-ratio: 0.2;
    top: calc(var(--base-top) + 10.9rem*var(--vertical-factor) + (var(--hero-card-height) + var(--hero-card-gutter))*0);
    left: calc(var(--base-left) + var(--first-column-x) + (var(--hero-card-width) + var(--hero-card-gutter))*0);
  }

  .HeroCardsTransition_card__8m_DB:nth-child(2) {
    --card-max-opacity: 0.55;
    --image-wall-increment: 1;
    --cards-column-translate-ratio: 0.2;
    top: calc(var(--base-top) + 10.9rem*var(--vertical-factor) + (var(--hero-card-height) + var(--hero-card-gutter))*1);
    left: calc(var(--base-left) + var(--first-column-x) + (var(--hero-card-width) + var(--hero-card-gutter))*0);
  }

  /* Column 2 */

  .HeroCardsTransition_card__8m_DB:nth-child(3) {
    --card-max-opacity: 0.85;
    --image-wall-increment: 2;
    --cards-column-translate-ratio: 0.85;
    top: calc(var(--base-top) + 24.3rem*var(--vertical-factor) + (var(--hero-card-height) + var(--hero-card-gutter))*0);
    left: calc(var(--base-left) + var(--first-column-x) + (var(--hero-card-width) + var(--hero-card-gutter))*1);
  }

  .HeroCardsTransition_card__8m_DB:nth-child(4) {
    --card-max-opacity: 0.85;
    --image-wall-increment: 2;
    --cards-column-translate-ratio: 0.85;
    top: calc(var(--base-top) + 24.3rem*var(--vertical-factor) + (var(--hero-card-height) + var(--hero-card-gutter))*1);
    left: calc(var(--base-left) + var(--first-column-x) + (var(--hero-card-width) + var(--hero-card-gutter))*1);
  }

  /* Column 3  */

  .HeroCardsTransition_card__8m_DB:nth-child(5) {
    --card-max-opacity: 1;
    --image-wall-increment: 0;
    --cards-column-translate-ratio: 0.4;
    top: calc(var(--base-top) + 2.4rem*var(--vertical-factor) + (var(--hero-card-height) + var(--hero-card-gutter))*0);
    left: calc(var(--base-left) + var(--first-column-x) + (var(--hero-card-width) + var(--hero-card-gutter))*2);
  }

  .HeroCardsTransition_card__8m_DB:nth-child(6) {
    --card-max-opacity: 1;
    --image-wall-increment: 1;
    --cards-column-translate-ratio: 0.4;
    top: calc(var(--base-top) + 2.4rem*var(--vertical-factor) + (var(--hero-card-height) + var(--hero-card-gutter))*1);
    left: calc(var(--base-left) + var(--first-column-x) + (var(--hero-card-width) + var(--hero-card-gutter))*2);
  }

  /* Column 4 */

  .HeroCardsTransition_card__8m_DB:nth-child(7) {
    --card-max-opacity: 0.8;
    --image-wall-increment: 0;
    --cards-column-translate-ratio: 0.6;
    top: calc(var(--base-top) + -18.5rem*var(--vertical-factor) + (var(--hero-card-height) + var(--hero-card-gutter))*0);
    left: calc(var(--base-left) + var(--first-column-x) + (var(--hero-card-width) + var(--hero-card-gutter))*3);
    display: block;
  }

  .HeroCardsTransition_card__8m_DB:nth-child(8) {
    --card-max-opacity: 0.8;
    --image-wall-increment: 1;
    --cards-column-translate-ratio: 0.6;
    top: calc(var(--base-top) + -18.5rem*var(--vertical-factor) + (var(--hero-card-height) + var(--hero-card-gutter))*1);
    left: calc(var(--base-left) + var(--first-column-x) + (var(--hero-card-width) + var(--hero-card-gutter))*3);
    display: block;
  }

  .HeroCardsTransition_card__8m_DB:nth-child(9) {
    --card-max-opacity: 0.8;
    --image-wall-increment: 1;
    --cards-column-translate-ratio: 0.6;
    top: calc(var(--base-top) + -18.5rem*var(--vertical-factor) + (var(--hero-card-height) + var(--hero-card-gutter))*2);
    left: calc(var(--base-left) + var(--first-column-x) + (var(--hero-card-width) + var(--hero-card-gutter))*3);
    display: block;
  }

  /* Column 5 */

  .HeroCardsTransition_card__8m_DB:nth-child(10) {
    --card-max-opacity: 0.6;
    --image-wall-increment: 0;
    --cards-column-translate-ratio: 0.2;
    top: calc(var(--base-top) + -32rem*var(--vertical-factor) + (var(--hero-card-height) + var(--hero-card-gutter))*0);
    left: calc(var(--base-left) + var(--first-column-x) + (var(--hero-card-width) + var(--hero-card-gutter))*4);
    display: block;
  }

  .HeroCardsTransition_card__8m_DB:nth-child(11) {
    --card-max-opacity: 0.6;
    --image-wall-increment: 1;
    --cards-column-translate-ratio: 0.2;
    top: calc(var(--base-top) + -32rem*var(--vertical-factor) + (var(--hero-card-height) + var(--hero-card-gutter))*1);
    left: calc(var(--base-left) + var(--first-column-x) + (var(--hero-card-width) + var(--hero-card-gutter))*4);
    display: block;
  }

  .HeroCardsTransition_card__8m_DB:nth-child(12) {
    --card-max-opacity: 0.6;
    --image-wall-increment: 1;
    --cards-column-translate-ratio: 0.2;
    top: calc(var(--base-top) + -32rem*var(--vertical-factor) + (var(--hero-card-height) + var(--hero-card-gutter))*2);
    left: calc(var(--base-left) + var(--first-column-x) + (var(--hero-card-width) + var(--hero-card-gutter))*4);
    display: block;
  }
}

