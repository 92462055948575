.listItemOl {
  composes: body from '~typo';
  display: list-item;
  margin-left: var(--spacer-24);
  list-style-type: decimal;

  .listItemOl {
    list-style-type: upper-alpha;

    .listItemOl {
      list-style-type: upper-roman;
    }
  }
}
